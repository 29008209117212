.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: calc(var(--wide-dimension) * 2);
  padding-bottom: calc(var(--wide-dimension) * 2);
}

.empty-state p {
  max-width: 500px;
}
