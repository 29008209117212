.client-outstanding-balance-banner .banner-content-wrapper {
  display: flex;
  align-items: center;
}

.client-outstanding-balance-banner .banner-content-wrapper > span {
  flex: 1;
  margin-right: var(--medium-dimension);
}

.client-outstanding-balance-banner
  .banner-content-wrapper
  > .button-link-appearance {
  color: var(--pure-white-color);
  text-decoration: underline;
}

.client-outstanding-balance-banner
  .banner-content-wrapper
  > .button-link-appearance:hover,
.client-outstanding-balance-banner
  .banner-content-wrapper
  > .button-link-appearance:active {
  color: var(--soft-blue-color);
}
