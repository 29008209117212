a.lawyer-summary-link {
  text-decoration: none;
  color: inherit;
}

.lawyer-summary {
  display: flex;
  align-items: center;
}

.lawyer-summary-content {
  flex: 1;
}

.lawyer-summary-accessory {
  display: block;
  margin-left: var(--medium-dimension);
  color: var(--list-card-accessory-color);
  text-decoration: inherit;
}

.lawyer-summary-staff-id {
  margin-bottom: 0;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.lawyer-summary-heading {
  display: block;
  color: inherit;
  text-decoration: inherit;
}

.lawyer-summary-heading h1 {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.lawyer-summary-body {
  display: flex;
  align-items: center;
}

.lawyer-summary-body:not(:empty) {
  margin-top: var(--medium-dimension);
}

.lawyer-summary-column {
  display: flex;
  align-items: center;
}

.lawyer-summary-column:first-child {
  margin-right: calc(var(--wide-dimension) * 2);
}

.lawyer-summary-column:not(:first-child):not(:last-child) {
  margin-right: var(--wide-dimension);
}

.lawyer-summary-column .label-value-pair span:first-child {
  margin-right: var(--narrow-dimension);
}

/* Skeleton */

.lawyer-summary-skeleton {
  background-color: var(--pure-white-color);
}

.lawyer-summary-skeleton .card.indented-card {
  border-left-color: var(--light-grey-color);
}

.lawyer-summary-skeleton
  .skeleton-row:first-child
  .skeleton-column:not(:last-child),
.lawyer-summary-skeleton
  .skeleton-row:last-child
  .skeleton-column:not(:last-child) {
  flex: 1;
}

.lawyer-summary-skeleton .skeleton-row:first-child .skeleton-column:last-child,
.lawyer-summary-skeleton .skeleton-row:last-child .skeleton-column:last-child {
  flex: 2;
}

.lawyer-summary-skeleton .skeleton-row:last-child .skeleton-bone {
  height: 20px;
}

.lawyer-summary-skeleton
  .skeleton-row:nth-child(2)
  .skeleton-column:first-child {
  flex: 1;
}

.lawyer-summary-skeleton
  .skeleton-row:nth-child(2)
  .skeleton-column:last-child {
  flex: 2;
}

.lawyer-summary-skeleton .skeleton-row:nth-child(2) .skeleton-bone {
  height: 40px;
  margin-bottom: var(--medium-dimension);
}
