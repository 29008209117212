.page .print-report-card {
  display: flex;
  border: 2px var(--medium-grey-color) solid;
  border-radius: 5px;
  padding: var(--medium-dimension);
}

.page .print-type-title-container {
  flex-grow: 1;
  align-self: center;
  font-size: 1.5rem;
}
