.release-page .release-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--wide-dimension);
}

.release-page .release-heading .release-heading-right,
.release-page .release-heading .release-heading-left {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.release-heading .release-heading-left h1 {
  margin-bottom: 0px;
}

.release-page .release-heading .date-container {
  display: flex;
  margin-left: var(--medium-dimension);
}
.release-page .release-heading .date-container .input-help {
  margin-right: var(--narrow-dimension);
  width: 138px;
}

.release-page .release-heading .release-heading-right .button-link-appearance {
  margin-right: var(--medium-dimension);
  text-decoration: underline;
  margin-bottom: var(--medium-dimension);
}
.release-page .release-heading .release-heading-right {
  min-width: fit-content;
}

.release-page .search-header {
  display: flex;
  justify-content: space-between;
}

.release-page .search-header .button.button-highlight {
  height: 66px;
  min-width: var(--page-action-button-width);
}

.release-page .search-header .general-search-input input {
  width: 464px;
}
.release-page .form-row.button-header {
  justify-content: flex-end;
}
.release-page .warning-text {
  margin-bottom: var(--narrow-dimension);
  margin-top: 0;
}
