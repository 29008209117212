.radio-group {
  @extend %input-fonts;
  display: inline-flex;
  padding-top: var(--input-padding-vertical);
  padding-bottom: var(--input-padding-vertical);
}

.radio-group label {
  display: inline-flex;
  align-items: center;
}

.radio-group label input[type='radio'] {
  @extend reset;
  position: relative;
  width: var(--radio-button-dimension);
  height: var(--radio-button-dimension);
  border-width: var(--radio-button-border-width);
  border-style: solid;
  border-color: var(--primary-content-color);
  border-radius: 50%;
  background-color: var(--pure-white-color);
}

/* FOCUS */

.radio-group label input[type='radio']:focus:not(:disabled) {
  border-color: var(--highlight-color);
  background-color: var(--soft-blue-color);
}

.radio-group label input[type='radio']:focus:not(:disabled) + span {
  color: var(--highlight-color);
  text-decoration: underline;
}

/* CHECKED */

.radio-group label input[type='radio']:checked {
  border-color: var(--highlight-color);
}

.radio-group label input[type='radio']:checked::before {
  display: block;
  position: relative;
  top: var(--radio-button-selection-inset);
  left: var(--radio-button-selection-inset);
  width: var(--radio-button-selection-dimension);
  height: var(--radio-button-selection-dimension);
  background-color: var(--highlight-color);
  border-radius: 50%;
  content: ' ';
}

/* DISABLED */

.radio-group label input[type='radio']:disabled {
  border-color: var(--input-disabled-color);
}

.radio-group label input[type='radio']:disabled:checked::before {
  background-color: var(--input-disabled-color);
}

.radio-group label input[type='radio']:disabled + span {
  color: var(--input-disabled-color);
}

/* LAYOUT */

.radio-group label input[type='radio']:not(:last-child) {
  margin-right: var(--narrow-dimension);
}

.radio-group.radio-group-horizontal {
  height: var(--input-calculated-height);
}

.radio-group.radio-group-horizontal label:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.radio-group.radio-group-vertical {
  flex-direction: column;
}
