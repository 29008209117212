.fab-action {
  @extend reset;
  @extend %button-fonts;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  height: var(--fab-action-height);
  padding: 0 var(--medium-dimension);
  background-color: var(--fab-secondary-background-color);
  color: var(--fab-secondary-content-color);
  cursor: pointer;
}

button.fab-action:not(:disabled):focus,
button.fab-action:not(:disabled):hover,
button.fab-action:not(:disabled):active,
a.fab-action:not(.fab-action-link-disabled):focus,
a.fab-action:not(.fab-action-link-disabled):hover,
a.fab-action:not(.fab-action-link-disabled):active {
  color: var(--fab-hover-color);
}

.fab-action:first-child {
  background-color: var(--fab-primary-background-color);
  color: var(--fab-primary-content-color);
}

button.fab-action:first-child:not(:disabled):focus,
button.fab-action:first-child:not(:disabled):hover,
button.fab-action:first-child:not(:disabled):active,
a.fab-action:first-child:not(.fab-action-link-disabled):focus,
a.fab-action:first-child:not(.fab-action-link-disabled):hover,
a.fab-action:first-child:not(.fab-action-link-disabled):active {
  background-color: var(--fab-hover-color);
  color: var(--fab-primary-content-color);
}

button.fab-action:not(:disabled):focus,
a.fab-action:not(.fab-action-link-disabled):focus {
  text-decoration: underline;
}

.fab-action:not(:first-child):not(:last-child) {
  border-top-width: var(--fab-inner-border-width);
  border-top-style: solid;
  border-top-color: var(--fab-inner-border-color);
}

.fab-action .fab-action-icon {
  min-width: var(--fab-icon-width);
  margin-right: var(--narrow-dimension);
}

.fab-action .fab-action-title {
  flex: 1;
  text-align: left;
  margin-right: var(--narrow-dimension);
}

.fab-action .fab-action-accessory {
  min-width: var(--fab-icon-width);
  margin-left: var(--narrow-dimension);
}

.fab-action:disabled,
.fab-action.fab-action-link-disabled {
  cursor: not-allowed;
}
