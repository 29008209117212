.companies-list-page-heading {
  margin-top: 0;
  margin-bottom: var(--wide-dimension);
  display: flex;
}

.companies-list-page-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.companies-list-page-heading-left h1 {
  margin-bottom: 0;
}

.companies-list-page-search-bar {
  @extend %block-element-margins;
  display: flex;
  justify-content: space-between;
}

.companies-list-page-search-bar .general-search-input {
  width: 50%;
  height: 100%;
  margin-bottom: 0;
}

.companies-list-page-search-bar .law-firm-sort-menu {
  margin-bottom: 0;
}

.companies-list {
  @extend %block-element-margins;
}

.companies-list-pagination-container {
  display: flex;
  justify-content: center;
}
