@import './properties';

.banner-container {
  position: fixed;
  top: var(--banner-container-top);
  bottom: var(--banner-container-bottom);
  left: var(--banner-container-left);
  right: var(--banner-container-right);
  width: var(--banner-container-width);
  min-width: var(--banner-container-min-width);
  max-width: var(--banner-container-max-width);
  z-index: 2000;
}

.banner-container:empty {
  display: none;
}

@media print {
  .banner-container {
    display: none;
  }
}

.banner {
  position: relative;
  width: 100%;
  margin-bottom: var(--banner-spacing);
  overflow: hidden;
  opacity: 0;
  background-color: var(--banner-background-color);
  border-color: var(--banner-border-color);
  border-width: var(--banner-border-width);
  border-radius: var(--banner-border-radius);
  border-style: var(--banner-border-style);
  transition-property: top, opacity;
  transition-duration: var(--banner-position-transition-duration),
    var(--banner-opacity-transition-duration);
  transition-timing-function: linear, ease-in-out;
}

.banner-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.banner-progress-value {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--banner-progress-value-background-color);
}

.banner-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: var(--banner-content-padding);
  color: var(--banner-content-color);
}

.banner-content-wrapper {
  flex: 1;
  margin-right: var(--banner-narrow-dimension);
}

.banner-dismiss-button {
  all: initial;
  font-family: var(--font-family);
  font-weight: var(--bold-font-weight);
  padding: var(--banner-dismiss-button-padding);
  color: var(--banner-dismiss-button-color);
  cursor: pointer;
}

.banner-dismiss-button:hover,
.banner-dismiss-button:active {
  opacity: 0.5;
}
