:root {
  --general-search-input-icon-container-width: 65px;
}

.general-search-input {
  @extend %block-element-margins;
  position: relative;
}

.general-search-input input {
  width: 100%;
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--general-search-input-icon-container-width);
  padding-right: var(--general-search-input-icon-container-width);
  background-color: var(--soft-blue-color);
}

.general-search-input input::placeholder {
  color: var(--dark-grey-color);
}

.general-search-input .search-input-wrapper {
  position: relative;
}

.general-search-input .search-input-wrapper button:disabled,
.general-search-input .search-input-wrapper .search-input-icon.disabled svg {
  color: var(--input-disabled-color);
  cursor: auto;
}

.general-search-input button.search-input-reset {
  @extend reset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--general-search-input-icon-container-width);
  height: 100%;
  color: var(--input-content-color);
  cursor: pointer;
}

.general-search-input .search-input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--general-search-input-icon-container-width);
  height: 100%;
  color: var(--input-content-color);
}
