:root {
  --legal-problem-code-form-right-inset: 15%;
}

.legal-problem-code-form > .form-row > .form-column:last-child,
.legal-problem-code-form > .form-row:nth-child(4) > .form-column:first-child {
  margin-right: var(--legal-problem-code-form-right-inset);
}

.legal-problem-code-form > .form-row:nth-child(1) > .form-column > .form-row {
  & > *:nth-child(1) {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 4;
  }

  & > *:nth-child(3) {
    flex: 7;
  }
}

.legal-problem-code-form
  > .form-row:nth-child(2)
  > .form-column:nth-child(1)
  > .form-row {
  & > * {
    flex: 1;
  }
}

.legal-problem-code-form > .form-row:nth-child(3) {
  & > *:nth-child(1),
  & > *:nth-child(2),
  & > *:nth-child(3) {
    flex: 3;
  }

  & > *:nth-child(4) {
    flex: 15;
  }
}

.legal-problem-code-form > .form-row:nth-child(4) > .form-column {
  & > .form-row:first-child {
    align-items: center;
    padding-bottom: var(--medium-dimension);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--primary-content-color);
  }

  & > .form-row:first-child > h3 {
    font-size: 1rem;
    font-weight: var(--bold-font-weight);
    margin-top: 0;
    margin-bottom: 0;
  }

  & > .form-row:nth-child(1),
  & > .form-row:nth-child(2),
  & > .form-row:nth-child(3) {
    justify-content: space-between;
  }

  & > .form-row:nth-child(4),
  & > .form-row:nth-child(5) {
    justify-content: flex-end;
  }

  & > .form-row:not(:first-child):not(:last-child) {
    margin-bottom: var(--medium-dimension);
  }

  & label.input-label {
    display: flex;
    align-items: center;
  }

  & label.input-label .input-label-content {
    margin-right: var(--narrow-dimension);
  }

  & .input-help {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  & .input-help .input-help-content,
  & input[type='text'] {
    width: 80px;
  }

  & .checkbox-container {
    display: flex;
    align-items: center;
    height: var(--input-calculated-height);
    margin-top: 0;
  }
}

.legal-problem-code-form > .form-row:nth-child(5) > .form-column {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--primary-content-color);
}

.legal-problem-code-form > .form-row:nth-child(6) {
  & > *:nth-child(1) {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 1;
  }

  & > *:nth-child(3) {
    flex: 2;
  }
}

.legal-problem-code-form .staff-close-or-void-input-group {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--medium-dimension);

  & .input-help:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  & .input-help:first-child .input-help-content,
  & .input-help:first-child select {
    width: 100px;
  }

  & .input-help:last-child input {
    width: 80px;
  }

  & label.input-label {
    display: flex;
    align-items: center;
  }

  & .input-label-content:not(:first-child) {
    margin-left: var(--narrow-dimension);
  }

  & .input-label-content:not(:last-child) {
    margin-right: var(--narrow-dimension);
  }
}

.legal-problem-code-form .staff-close-or-void-input-group-text {
  @extend %input-label-content;
  line-height: var(--input-calculated-height);
  margin-left: var(--narrow-dimension);
  margin-right: var(--narrow-dimension);
}

@media print {
  .legal-problem-code-form {
    margin: 0;
    padding: 0;
  }
  .legal-problem-code-form .form-row:nth-child(1) {
    margin-bottom: var(--narrow-dimension);
  }
  .legal-problem-code-form .form-row.print-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
  .legal-problem-code-form .form-row.print-area .form-row:nth-child(2) {
    margin-bottom: var(--narrow-dimension);
    margin-top: 0;
  }
  .legal-problem-code-form
    > .form-row:nth-child(3)
    > .form-column:nth-child(1) {
    flex: 4;
  }
  .legal-problem-code-form
    > .form-row:nth-child(3)
    > .form-column:nth-child(2) {
    flex: 1;
  }
  .legal-problem-code-form > .form-row > .form-column:last-child,
  .legal-problem-code-form > .form-row:nth-child(4) > .form-column:first-child {
    margin: 0 var(--narrow-dimension);
  }
}
