:root {
  --manage-codes-modal-checkbox-width: 18px;
}

.manage-codes-modal .search-input-wrapper {
  max-width: 460px;
}

.manage-codes-modal .modal-content {
  width: 884px;
}

.manage-codes-modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--medium-dimension);
}

.manage-codes-modal-heading h1 {
  flex: 1;
  margin-right: var(--medium-dimension);
  margin-bottom: 0;
}

.manage-codes-modal-heading .filter-input {
  flex: 2;
  margin-bottom: 0;
}

.manage-codes-modal .sortable-table thead {
  display: block;
  width: 100%;
}

.manage-codes-modal .sortable-table tbody {
  display: block;
  width: 100%;
  max-height: 440px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  border: 1px solid var(--table-header-background-color);
  border-top: 0;
  border-bottom-left-radius: var(--table-border-radius);
  border-bottom-right-radius: var(--table-border-radius);
}

.manage-codes-modal .sortable-table tr {
  display: flex;
  align-items: center;
  width: 100%;
}

.manage-codes-modal .sortable-table tr > * {
  flex: 5;
}

.manage-codes-modal .sortable-table tr > *:first-child {
  flex: 2;
}

.manage-codes-modal .sortable-table tr > th:first-child button {
  padding-left: calc(
    var(--manage-codes-modal-checkbox-width) + var(--medium-dimension)
  );
}

.manage-codes-modal .sortable-table tr > td:first-child {
  display: flex;
  align-items: center;
}

.manage-codes-modal .sortable-table tr:nth-child(2n + 2) {
  background-color: var(--x-light-grey-color);
}

.manage-codes-modal .sortable-table .checkbox-container {
  width: var(--manage-codes-modal-checkbox-width);
  margin-top: 0;
  margin-right: var(--medium-dimension);
}

.manage-codes-modal .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.manage-codes-modal .button-bar > button {
  margin-bottom: 0;
}

.manage-codes-modal .button-bar > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}
