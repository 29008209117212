.primary-client-integrity-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--medium-dimension);

  & > *:not(:last-child) {
    margin-right: var(--medium-dimension);
    margin-bottom: 0;
  }
}

.primary-client-integrity-table tr td:last-child {
  text-align: right;

  & > button {
    display: inline-flex;
  }
}

.client-status-modal .form-row:not(:nth-child(3)) {
  display: flex;
  justify-content: space-between;
  padding-top: var(--input-padding-vertical);
  padding-bottom: var(--input-padding-vertical);
  margin-bottom: 0;

  & > span:first-child {
    font-weight: var(--bold-font-weight);
    margin-right: var(--medium-dimension);
  }
}

.client-status-modal .input-help {
  width: 100%;
}

.client-status-modal .input-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & .input-label-content {
    font-size: var(--body-font-size);
    font-weight: var(--bold-font-weight);
  }

  & input {
    width: 2rem;
  }
}

.client-status-modal .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-right: var(--medium-dimension);
  }
}
