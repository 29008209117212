.cheque-run-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cheque-run-actions .cheque-run-action-inputs {
  display: flex;
  margin-bottom: calc(var(--wide-dimension) * 2);
}

.cheque-run-actions .cheque-run-action-inputs > * {
  max-width: 140px;
}

.cheque-run-actions .cheque-run-action-inputs > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.cheque-run-actions .cheque-run-action-buttons .button {
  min-width: var(--cheque-run-modal-button-min-width);
}

.cheque-run-actions .cheque-run-action-buttons .button:not(:last-child) {
  margin-right: var(--medium-dimension);
}
