:root {
  --breadcrumbs-height: 40px;
  --breadcrumbs-border-width: var(--border-width);
  --breadcrumbs-outer-arrow-side: calc(var(--breadcrumbs-height) / 2);
  --breadcrumbs-outer-arrow-point: calc(
    var(--breadcrumbs-outer-arrow-side) / 2
  );
  --breadcrumbs-inner-arrow-side: calc(
    var(--breadcrumbs-outer-arrow-side) - var(--breadcrumbs-border-width)
  );
  --breadcrumbs-inner-arrow-point: calc(
    var(--breadcrumbs-outer-arrow-point) - (var(--breadcrumbs-border-width) / 2)
  );
  --breadcrumbs-inner-arrow-padding: var(--wide-dimension);

  --breadcrumbs-border-color: var(--light-grey-color);
  --breadcrumbs-background-color: var(--pure-white-color);
  --breadcrumbs-content-color: var(--dark-blue-grey-color);

  --breadcrumbs-active-border-color: var(--dark-blue-grey-color);
  --breadcrumbs-active-background-color: var(--dark-blue-grey-color);
  --breadcrumbs-active-content-color: var(--pure-white-color);

  --breadcrumbs-focus-background-color: var(--soft-blue-color);
}

.breadcrumbs {
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  height: var(--breadcrumbs-height);
  margin: 0;
}

.breadcrumbs li {
  display: inline-block;
  position: relative;
  background-color: var(--breadcrumbs-border-color);
  height: var(--breadcrumbs-height);
}

.breadcrumbs li:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.breadcrumbs li:after {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-top: var(--breadcrumbs-outer-arrow-side) solid transparent;
  border-bottom: var(--breadcrumbs-outer-arrow-side) solid transparent;
  border-left: var(--breadcrumbs-outer-arrow-point) solid
    var(--breadcrumbs-border-color);
  position: absolute;
  top: 0;
  left: 100%;
}

.breadcrumbs li a {
  display: flex;
  align-items: center;
  position: relative;
  top: var(--breadcrumbs-border-width);
  left: var(--breadcrumbs-border-width);
  width: calc(100% - (var(--breadcrumbs-border-width) * 2));
  height: calc(100% - (var(--breadcrumbs-border-width) * 2));
  background-color: var(--breadcrumbs-background-color);
  color: var(--breadcrumbs-content-color);
  white-space: nowrap;
  padding-left: var(--breadcrumbs-inner-arrow-padding);
  padding-right: var(--breadcrumbs-inner-arrow-padding);
  font-size: 1rem;
  font-weight: var(--bold-font-weight);
  text-decoration: none;
}

.breadcrumbs li:not(:first-child) a {
  padding-left: 37px;
}

.breadcrumbs li:not(.breadcrumbs-icon) a:focus {
  text-decoration: underline;
}

.breadcrumbs li a:focus {
  outline: none;
}

.breadcrumbs li a:after {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-top: var(--breadcrumbs-inner-arrow-side) solid transparent;
  border-bottom: var(--breadcrumbs-inner-arrow-side) solid transparent;
  border-left: var(--breadcrumbs-inner-arrow-point) solid
    var(--breadcrumbs-background-color);
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
}

.breadcrumbs li:not(:last-child) a:focus {
  background-color: var(--breadcrumbs-focus-background-color);

  &:after {
    border-left-color: var(--breadcrumbs-focus-background-color);
  }
}

.breadcrumbs li:last-child {
  background-color: var(--breadcrumbs-active-border-color);
}

.breadcrumbs li:last-child:after {
  border-left-color: var(--breadcrumbs-active-border-color);
}

.breadcrumbs li:last-child a {
  background-color: var(--breadcrumbs-active-background-color);
  color: var(--breadcrumbs-active-content-color);
}

.breadcrumbs li:last-child a:after {
  border-left-color: var(--breadcrumbs-active-border-color);
}

.breadcrumbs li.breadcrumbs-icon a {
  padding-left: var(--narrow-dimension);
  padding-right: var(--narrow-dimension);
}

.breadcrumbs li.breadcrumbs-icon a > div {
  display: flex;
  align-items: center;
}

.breadcrumbs li.breadcrumbs-icon .fa-icon {
  font-size: 1.5rem;
}
