.list-button-card {
  padding: 0;
}

.list-button-card button {
  @extend %card-border;
  @extend %block-element-margins;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--wide-dimension);
  background-color: var(--card-background-color);
}

.list-button-card button .list-button-card-content > h2 {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: var(--dimension-unit);
  color: var(--list-card-heading-color);
  text-align: left;
}

.list-button-card button .list-button-card-content > p {
  margin: 0;
  color: var(--list-card-subheading-color);
  text-align: left;
}

.list-button-card button .list-button-card-accessory {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--list-button-card-accessory-color);
}

.list-button-card button:hover,
.list-button-card button:focus {
  background: var(--soft-blue-color);
  transition: 0.2s;
  cursor: pointer;
}
