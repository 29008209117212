.reports-folders-list-title-bar {
  display: flex;
  align-items: center;
  margin-bottom: var(--medium-dimension);
}

.reports-folders-list-title-bar > h1 {
  flex: 1;
  margin-bottom: 0;
}

.reports-folders-list-title-bar > .button {
  display: block;
}

.reports-folders-list-pagination-container {
  display: flex;
  justify-content: center;
}

ul.reports-folders-list {
  margin-right: calc(var(--wide-dimension) * -1);
}

ul.reports-folders-list > li {
  background-color: var(--primary-background-color);
}

ul.reports-folders-list > li.selected {
  background-color: var(--secondary-background-color);
}

ul.reports-folders-list > li > a {
  display: flex;
  align-items: center;
  padding: var(--medium-dimension);
  text-decoration: none;
  color: inherit;
}

.reports-folders-list-item-content {
  flex: 1;
}

.reports-folders-list-item-content > h4 {
  font-weight: var(--bold-font-weight);
  margin-bottom: 0;
}
