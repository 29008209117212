.overlay-container {
  position: relative;
}

.overlay-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-background-color);
  opacity: 0.7;
  z-index: 100;
}
