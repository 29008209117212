.deactivate-modal .button-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.deactivate-modal .button-bar button {
  margin-bottom: 0;
  margin-left: var(--wide-dimension);
}
