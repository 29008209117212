.union-print-details {
  padding: var(--medium-dimension);
  margin-bottom: var(--wide-dimension);
  background-color: var(--primary-background-color);
  -webkit-print-color-adjust: exact;
}

.union-print-details h2 {
  font-size: 1.25rem;
}

.union-print-details-row {
  display: flex;
}

.union-print-details-row:not(:last-child) {
  margin-bottom: var(--medium-dimension);
}

.union-print-details-item {
  flex: 1;
}

.union-print-details-item:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.union-print-details-row:nth-child(2) {
  & .union-print-details-item:nth-child(2),
  & .union-print-details-item:nth-child(3) {
    flex: 2;
  }

  & .union-print-details-item:nth-child(4) {
    flex: 3;
  }
}

.union-print-details-row:nth-child(4) {
  & .union-print-details-item {
    flex: 4;
  }

  & .union-print-details-item:nth-child(4) {
    flex: 5;
  }
}

.union-print-details-item > span {
  display: block;
}

.union-print-details-item > span:first-child {
  font-weight: var(--bold-font-weight);
}
/* PRINT HEADER */
.union-print-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.union-print-header h1 span {
  display: inline-block;
}

.union-print-header h1 span:last-child {
  margin-left: var(--medium-dimension);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
  text-transform: uppercase;
  color: var(--medium-grey-color);
}
