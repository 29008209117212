@keyframes activity-indicator-dot {
  0% {
    background-color: var(--activity-indicator-dot-highlight-color);
  }
  12.5% {
    background-color: var(--activity-indicator-dot-highlight-color);
  }
  25% {
    background-color: var(--activity-indicator-dot-highlight-color);
  }
  37.5% {
    background-color: var(--activity-indicator-dot-color);
  }
  50% {
    background-color: var(--activity-indicator-dot-color);
  }
  62.5% {
    background-color: var(--activity-indicator-dot-color);
  }
  75% {
    background-color: var(--activity-indicator-dot-color);
  }
  87.5% {
    background-color: var(--activity-indicator-dot-color);
  }
  100% {
    background-color: var(--activity-indicator-dot-color);
  }
}
