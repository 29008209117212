.checkbox-container {
  margin-bottom: 0;
  margin-top: var(--input-line-height);
}

.checkbox-container label {
  color: var(--dark-blue-grey-color);
  display: flex;
  align-items: center;
}

.checkbox-container label:focus {
  outline: none;
}

.checkbox-container input {
  display: none;
}

/*unchecked*/
.checkbox-container input[type='checkbox']:not(:checked) ~ .fa-icon.unchecked {
  display: block;
}

.checkbox-container input[type='checkbox']:not(:checked) ~ .fa-icon.checked {
  display: none;
}

/*checked*/

.checkbox-container input[type='checkbox']:checked ~ .fa-icon.unchecked {
  display: none;
}

.checkbox-container input[type='checkbox']:checked ~ .fa-icon.checked {
  display: block;
}

.checkbox-container span {
  font-size: 1rem;
  font-weight: var(--regular-font-weight);
  color: var(--primary-content-color);
  margin-left: var(--narrow-dimension);
}

.checkbox-container:not(.disabled) label:focus {
  text-decoration: underline;
}

.checkbox-container.disabled label {
  color: var(--primary-muted-content-color);
  cursor: not-allowed;
}
