.page.home .home-search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(var(--wide-dimension) * 3);
  padding-bottom: calc(var(--wide-dimension) * 3);
}

.page.home .home-search-container h1,
.page.home .home-search-container .search-input {
  margin-bottom: var(--wide-dimension);
}

.page.home .home-action-buttons {
  display: flex;
  justify-content: center;
}

.page.home .home-action-buttons > a {
  padding: 0;
  margin: 0;

  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    color: var(--dark-blue-grey-color);
    outline: none;

    & > span {
      text-decoration: underline;
    }
  }
}

.page.home .home-action-buttons > a .fa-icon {
  font-size: 1em;
  margin-right: var(--narrow-dimension);
}

.page.home .home-action-buttons > *:not(:last-child) {
  margin-right: var(--wide-dimension);
}

.page.home .home-recent-cases h1 {
  text-align: center;
  margin-bottom: var(--wide-dimension);
}

.page.home .recent-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--medium-dimension);
  grid-row-gap: var(--medium-dimension);
}

.page.home .recent-items > * {
  margin: 0;
}

.page.home .recent-items .activity-indicator-card,
.page.home .recent-items .empty-state {
  grid-column: 1 / 4;
}

.page.home .search-input-content {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.page.home .home-response-404 {
  text-align: center;
  margin-bottom: var(--wide-dimension);

  & h1 {
    margin-bottom: var(--medium-dimension);
  }

  & p {
    margin-bottom: 0;
  }
}
