%body-fonts {
  font-family: var(--font-family);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
}

%heading-fonts {
  font-family: var(--font-family);
  font-weight: var(--regular-font-weight);
}
