.print-layout {
  width: 100vw;
  height: 100vh;
  max-width: 8.5in;
  max-height: 11in;
}

.print-safe-area {
  width: 100%;
  height: 100%;
}

@media screen {
  .print-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8.5in;
    height: 11in;
    margin: 0 auto;
    background-color: var(--pure-white-color);
  }

  .print-safe-area {
    width: 7in;
    height: 9.5in;
  }
}
