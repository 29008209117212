.page.client-documents .fa-icon.document-type-icon {
  font-size: 2rem;
}

.client-documents-file-input {
  position: relative;
}

.client-documents-file-input input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
}

.client-document-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.client-document-actions > *:not(:last-child) {
  margin-right: var(--wide-dimension);
}

.client-document-actions .fa-icon {
  font-size: 1.25rem;
}
