.database-ltb-list-card .list-card-content > p > span {
  display: block;
}

.database-restore-modal {
  min-width: 480px;
  max-width: calc(100vw - (var(--medium-dimension) * 2));
}

.database-restore-modal-content ul {
  list-style: none;
  padding: 0;
}

.database-backups-page ul.logs {
  list-style: none;
  padding: var(--medium-dimension);
  min-height: 350px;
  word-break: break-all;
  background-color: var(--input-readonly-color);

  & > li:not(:last-child) {
    margin-bottom: var(--narrow-dimension);
  }
}

.database-backups-heading {
  display: flex;
  align-items: center;
  margin-bottom: var(--medium-dimension);

  & > h1 {
    flex: 1;
    margin-bottom: 0;
  }

  & > a {
    margin-left: var(--medium-dimension);
  }
}

.database-backups-page .subheading {
  margin-top: calc(0px - var(--medium-dimension));
}

.database-restore-modal-content .button-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--medium-dimension);

  & > button {
    margin: 0;
  }

  & > button:not(:last-child) {
    margin-right: var(--medium-dimension);
  }
}
