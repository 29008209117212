.lawyer-types-heading {
  @extend %block-element-margins;
  display: flex;
}

.lawyer-types-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.lawyer-types-heading-left h1 {
  margin-bottom: 0;
}
