.lawyer-feedback-card .lawyer-feedback-card-header,
.lawyer-feedback-card .lawyer-feedback-card-body:not(:last-child) {
  @extend %block-element-margins;
}

.lawyer-feedback-card .lawyer-feedback-card-header,
.lawyer-feedback-card .lawyer-feedback-card-body {
  display: flex;
  align-items: flex-start;
}

.lawyer-feedback-card .lawyer-feedback-card-icon-container {
  width: 1.25rem;
  margin-right: var(--medium-dimension);
  color: var(--medium-grey-color);
}

.lawyer-feedback-card
  .lawyer-feedback-card-icon-container.lawyer-feedback-positive {
  color: var(--success-color);
}

.lawyer-feedback-card
  .lawyer-feedback-card-icon-container.lawyer-feedback-negative {
  color: var(--error-color);
}

.lawyer-feedback-card
  .lawyer-feedback-card-body
  > .lawyer-feedback-card-icon-container
  > .fa-icon {
  transform: rotateY(180deg);
}

.lawyer-feedback-card .lawyer-feedback-card-content {
  width: 100%;
}

.lawyer-feedback-card .lawyer-feedback-card-footer {
  display: flex;
  justify-content: flex-end;
}

.lawyer-feedback-card .lawyer-feedback-card-footer button:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.lawyer-feedback-card ul.lawyer-feedback-card-meta {
  flex: 1;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--medium-grey-color);
}

.lawyer-feedback-card ul.lawyer-feedback-card-meta > li:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.lawyer-feedback-card .lawyer-feedback-print-properties {
  margin-top: var(--medium-dimension);
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: var(--wide-dimension);
  }

  & > *:not(:last-child) {
    margin-right: var(--wide-dimension);
  }

  & .label-value-pair {
    display: flex;
    flex-direction: column;
  }

  & .label-value-pair span:first-child {
    font-weight: var(--bold-font-weight);
    margin-bottom: var(--narrow-dimension);
  }
}

.lawyer-feedback-card form.lawyer-feedback-screen-properties {
  margin-top: var(--wide-dimension);
}

.lawyer-feedback-card form.lawyer-feedback-screen-properties .form-row {
  & > * {
    flex: 4;
  }

  & > *:nth-child(4) {
    flex: 6;
  }

  & > *:nth-child(5) {
    flex: 6;
  }
}

.lawyer-feedback-card ul.lawyer-feedback-documents {
  margin-top: 0;
  margin-bottom: 0;
}

.lawyer-feedback-card ul.lawyer-feedback-documents:not(:empty) {
  margin-top: calc(var(--medium-dimension) + var(--wide-dimension));
}

.lawyer-feedback-card ul.lawyer-feedback-documents:empty {
  margin-top: 0;
  margin-bottom: 0;
}

.lawyer-feedback-card ul.lawyer-feedback-documents > li:not(:last-child) {
  @extend %block-element-margins;
}

.lawyer-feedback-card ul.lawyer-feedback-documents > li {
  @extend reset;
  @extend %feedback-document;
  width: 50%;
}

.lawyer-feedback-card ul.lawyer-feedback-documents > li button {
  cursor: pointer;
  border: none;
  background-color: var(--input-readonly-color);
  padding: 5px;
  margin-left: var(--narrow-dimension);
  border-radius: 50%;
}

.lawyer-feedback-card ul.lawyer-feedback-documents > li > button:disabled {
  cursor: not-allowed;
}

.lawyer-feedback-card ul.lawyer-feedback-documents > li > button > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lawyer-feedback-card ul.lawyer-feedback-documents > li > button > .fa-icon {
  margin-left: var(--medium-dimension);
}

.lawyer-feedback-cards-list {
  @extend %block-element-margins;
}

.lawyer-feedback-cards-list .lawyer-feedback-card {
  margin: 0;
}

.lawyer-feedback-cards-list .lawyer-feedback-card:nth-child(2n + 2) {
  background-color: var(--soft-yellow-color);
}

@media print {
  .lawyer-feedback-card {
    break-inside: avoid;
    /* Remove padding to compensate for the bottom margin on the print properties: */
    padding-bottom: 0;
  }

  .lawyer-feedback-card .lawyer-feedback-card-body:not(:last-child) {
    margin-bottom: 0;
  }

  .lawyer-feedback-card {
    background-color: var(--x-light-grey-color);
  }

  .lawyer-feedback-cards-list .lawyer-feedback-card:nth-child(2n + 2) {
    background-color: var(--pure-white-color);
  }
}
