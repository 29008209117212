.run-edit-report-modal .form-row .button,
.run-edit-report-modal .button-container .button {
  height: 60px;
  min-width: 140px;

  &:not(:last-child) {
    margin-right: var(--wide-dimension);
  }
}

.run-edit-report-modal .button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--medium-dimension);
}

.run-edit-report-modal .button-bar {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--medium-dimension);
}

.run-edit-report-modal .button-bar .button {
  min-width: 140px;
  height: 60px;
  margin-bottom: 0;
}

.run-edit-report-modal .button-bar .dropdown-menu {
  min-width: 180px;
  margin-bottom: 0;
}

.run-edit-report-modal .button-bar .dropdown-menu-option {
  padding-top: 0;
  padding-bottom: 0;
  height: 56px;
}

.run-edit-report-modal .button-bar > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.run-edit-report-modal .form-row.edit-form-row {
  margin-top: var(--medium-dimension);
  margin-bottom: calc(var(--medium-dimension) * 2);

  & > .input-help {
    width: calc(50% + var(--medium-dimension));
  }
}

.run-edit-report-modal .form-row.run-form-row {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: var(--medium-dimension);
  margin-bottom: calc(var(--medium-dimension) * 2);
}

.run-edit-report-modal .report-date-range-form > p {
  margin-bottom: var(--medium-dimension);
  font-weight: 400;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.form-row.report-filter-form-row {
  & > *:not(button) {
    flex: 1;
  }
}

.report-date-range-form > .form-row {
  display: flex;
  align-items: flex-end;

  & > *:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > *:nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & > *:nth-child(3) {
    flex: 3;
  }

  & label:not(:last-child) {
    margin-bottom: var(--narrow-dimension);
  }
}
