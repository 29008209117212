.auth-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url('/assets/css-images/background-login.png');
  background-position: center;
  background-size: cover;
}

.auth-placeholder-spacer:first-child {
  flex: 1;
}

.auth-placeholder-spacer:last-child {
  flex: 2;
}

.auth-placeholder-content {
  width: 300px;
  padding: var(--wide-dimension);
  background-color: var(--pure-white-color);
  border: 1px solid #dddddd;
}

.auth-placeholder-content img {
  display: block;
  max-width: 100%;
}

.auth-placeholder-content p {
  margin-top: var(--medium-dimension);
  margin-bottom: 0;
  text-align: center;
}

.auth-placeholder-content button,
.auth-placeholder-content a.button {
  width: 100%;
  padding: var(--medium-dimension);
  margin-top: var(--medium-dimension);
}
