.expanding-card {
  @extend %block-element-margins;
  overflow: hidden;
}

.expanding-card-content {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.expanding-card.expanded:not(.animating),
.expanding-card.expanded:not(.animating) .expanding-card-content {
  overflow: visible;
}
