.activity-indicator {
  position: relative;
}

.activity-indicator-dot {
  position: absolute;
  animation-name: activity-indicator-dot;
  animation-iteration-count: infinite;
  animation-timing-function: step-end;
}

.card.activity-indicator-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.card.activity-indicator-card h2 {
  margin-top: var(--medium-dimension);
  margin-bottom: 0;
  margin-left: 0.5em;
  color: var(--activity-indicator-message-color);
}
