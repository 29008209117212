.case-correction-form > .form-row:first-child {
  & > *:first-child {
    flex: 3;
  }

  & > *:nth-child(2) {
    flex: 3;
  }

  & > *:nth-child(3) {
    flex: 6;
  }
}
