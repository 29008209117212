.save-as-modal {
  min-width: 620px;
}

.save-as-modal .input-button-container {
  flex-direction: column;
}

.save-as-modal .input-button-container button.button-link-appearance {
  align-self: end;
  position: relative;
  top: 1rem;
  margin-right: 0;
}

.save-as-modal .form-row:nth-of-type(3) {
  margin-bottom: 0;
}

.save-as-modal .button-container {
  display: flex;
  justify-content: end;
}

.save-as-modal .button-container .button {
  height: 60px;
  min-width: 140px;
  margin-left: var(--medium-dimension);
}
