.save-modal {
  width: 500px;
  max-width: 100%;
}

.save-modal .button-bar {
  display: flex;
  justify-content: flex-end;
}

.save-modal .button-bar button {
  margin-bottom: 0;
}

.save-modal .button-bar button:not(:last-child) {
  margin-right: var(--medium-dimension);
}
