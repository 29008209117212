.select-lawfirm-modal {
  width: 875px;
}
.select-lawfirm-modal .select-lawfirm-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--medium-dimension);
}

.select-lawfirm-modal .select-lawfirm-modal-header > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-lawfirm-modal .select-lawfirm-modal-header h2,
.select-lawfirm-modal .select-lawfirm-modal-header p {
  margin-bottom: 0;
  margin-right: var(--medium-dimension);
}

.select-lawfirm-modal .list-button-card button {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
}

.select-lawfirm-modal .list-button-card .list-button-card-content h2 {
  font-weight: initial;
}

.select-lawfirm-pagination-container {
  display: flex;
  justify-content: center;
}
