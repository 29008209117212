:root {
  --office-card-heading-content-color: var(--pure-white-color);
}

.office-card {
  background-color: var(--dark-blue-grey-color);
  border-radius: 5px;
}

.office-card-heading {
  display: flex;
  align-items: center;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
}

.office-card-heading-expanded {
  align-items: baseline;
}

.office-card-heading > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.office-card-heading > h2,
.office-card-heading > .input-help {
  margin-bottom: 0;
  color: var(--office-card-heading-content-color);
}

.office-card-heading > h2:first-child,
.office-card-heading > .input-help:first-child {
  width: 3.5rem;
}

.office-card-heading > h2:nth-child(2),
.office-card-heading > .input-help:nth-child(2) {
  flex: 1;
}

.office-card-heading > h2:nth-child(2) {
  display: flex;
  align-items: center;

  & .tag {
    margin-left: var(--medium-dimension);
  }
}

.office-card-heading input[type='text'],
.office-card-heading input[type='text']:focus:not(:read-only):not(:disabled),
.office-card-heading input[type='text']::placeholder {
  @extend reset;
  @extend %heading-fonts;
  font-size: var(--h2-font-size);
  color: var(--office-card-heading-content-color);
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--office-card-heading-content-color);
  width: 100%;
}

.office-card-heading input[type='text']::placeholder {
  opacity: 0.7;
}

.office-card-actions {
  display: flex;
}

.office-card-actions > button.button-link-appearance {
  color: var(--office-card-heading-content-color);
}

.office-card-actions > button.button-link-appearance:hover,
.office-card-actions > button.button-link-appearance:focus,
.office-card-actions > button.button-link-appearance:active {
  color: var(--office-card-heading-content-color);
  opacity: 0.7;
}

.office-card-actions > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.office-card-content {
  background-color: var(--secondary-background-color);
}

.office-card-content form {
  padding: var(--medium-dimension);
}

.office-card-content form > .form-row > * {
  flex: 1;
}

.office-card-content form > .form-row:first-child > *:nth-child(4) {
  flex: 1.25;
}

.office-card-content form > .form-row:nth-child(2) {
  & > *:first-child {
    flex: 8;
  }

  & > *:nth-child(2) {
    flex: 5;
  }

  & > *:nth-child(3) {
    flex: 5;
  }

  & > *:nth-child(4) {
    flex: 3;
  }

  & > *:nth-child(5) {
    flex: 3;
  }
}

.office-card-required-fields {
  display: flex;
}

.office-card-required-fields .checkbox-container {
  margin-top: 0;
}

.office-card-required-fields .checkbox-container:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.office-card .deactivate-toggle-container {
  margin-top: 1.25rem;
}
