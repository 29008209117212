.client-outstanding-balance-notes-heading {
  @extend %block-element-margins;
}

.client-outstanding-balance-notes-heading p {
  margin-bottom: 0px;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.client-outstanding-balance-notes-heading h1 {
  margin-bottom: 0;
}

.client-outstanding-balance-notes-actions {
  @extend %block-element-margins;
  display: flex;
  justify-content: space-between;
}

.client-outstanding-balance-notes-actions .notes-sort-menu {
  margin-bottom: 0;
}

.client-outstanding-balance-notes-actions button {
  height: 100%;
}
