.reports-folder-detail-form {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--medium-dimension);
}

.reports-folder-detail-form > :not(:last-child) {
  margin-right: var(--medium-dimension);
}

.reports-folder-detail-form > .input-help {
  flex: 1;
}

.reports-folder-detail-form input[type='text'],
.reports-folder-detail-form
  input[type='text']:focus:not(:read-only):not(:disabled),
.reports-folder-detail-form input[type='text']::placeholder {
  @extend reset;
  @extend %heading-fonts;
  font-size: var(--h1-font-size);
  color: var(--primary-content-color);
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--primary-content-color);
  width: 100%;
}

.reports-folder-detail-form .button {
  min-width: 180px;
  height: 51px;
}

.reports-folder-detail-form a.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
