%card-border {
  border-width: var(--border-width);
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: var(--card-border-color);
}

%indented-card-border {
  border-left-width: var(--card-indent-width);
  border-left-style: solid;
  border-left-color: var(--card-indent-color);
}

%case-list-card-colors {
  border-color: var(--case-list-card-border-color);
  background-color: var(--case-list-card-background-color);
}

%feedback-document {
  @extend %input-fonts;
  @extend %input-borders;
  @extend %input-readonly;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--medium-dimension);
}
