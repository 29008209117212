.cheque-run-view .general-search-input {
  max-width: 50%;
}

.cheque-print-options {
  display: flex;
  justify-content: flex-end;
}

.cheque-print-options > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.cheque-run-pagination-container {
  display: flex;
  justify-content: center;
}
