.page.cheque-run-page ul.tabs > li.tabs-item {
  min-width: 220px;
  text-align: center;
  border-bottom: 0;
}

.page.cheque-run-page ul.tabs > li.tabs-item > a {
  width: 100%;
}

.cheque-run-page-heading {
  @extend %block-element-margins;
  display: flex;
}

.cheque-run-page-heading-left {
  flex: 1;
}

.button.cheque-run-action-button {
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--wide-dimension);
  padding-right: var(--wide-dimension);
  min-width: var(--page-action-button-width);
}
