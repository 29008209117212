:root {
  --cheque-run-modal-icon-color: var(--highlight-color);
  --cheque-run-modal-icon-opacity: 0.5;
  --cheque-run-modal-button-min-width: 200px;
}

.cheque-run-modal .modal-content {
  padding: 0;
}

.cheque-run-modal-content {
  width: 480px;
  max-width: 100%;
}

.cheque-run-modal-content-unlocked {
  padding: var(--wide-dimension);
}

.cheque-run-modal-content-unlocked .cheque-run-lock-icon-group {
  position: relative;
  width: 9rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--wide-dimension);
  text-align: center;
}

.cheque-run-modal-content-unlocked .cheque-run-lock-icon {
  font-size: 6rem;
  margin-bottom: 1rem;
  color: var(--cheque-run-modal-icon-color);
  opacity: var(--cheque-run-modal-icon-opacity);
}

.cheque-run-modal-content-unlocked .cheque-run-alert-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 3rem;
  color: var(--error-color);
}

.cheque-run-modal-content-unlocked .cheque-run-lock-alert {
  text-align: center;
  margin-bottom: var(--wide-dimension);
}

.cheque-run-modal-content-unlocked .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cheque-run-modal-content-unlocked .button-bar > button.button {
  min-width: var(--cheque-run-modal-button-min-width);
  margin-bottom: 0;
}

.cheque-run-modal-content-unlocked .button-bar > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.cheque-run-modal-content-locked h2 {
  text-align: center;
  margin-bottom: calc(var(--medium-dimension) * 2);
}

.cheque-run-modal-content-locked-body {
  padding: var(--wide-dimension);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--light-grey-color);
}

.cheque-run-modal-content-locked-body > .fa-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--narrow-dimension);
  font-size: 4rem;
  color: var(--cheque-run-modal-icon-color);
  opacity: var(--cheque-run-modal-icon-opacity);
}

.cheque-run-modal-content-locked-footer {
  padding: var(--wide-dimension);
  text-align: center;
}

.cheque-run-modal-content-locked-footer button.button {
  min-width: var(--cheque-run-modal-button-min-width);
}

.cheque-run-modal-confirm-delete-run {
  text-align: center;
}

.cheque-run-modal-confirm-delete-run .button-bar {
  display: flex;
}

.cheque-run-modal-confirm-delete-run .button-bar button {
  flex: 1;
  margin-bottom: 0;
}
