.law-firm-details-page-heading {
  @extend %block-element-margins;
  display: flex;
}

.law-firm-details-page-heading-left {
  flex: 1;
  display: flex;

  flex-direction: column;
}
.law-firm-details-page-heading-left > div {
  display: flex;
}

.law-firm-details-page-heading-left p {
  margin-bottom: 0px;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.law-firm-details-page-heading-left h1 {
  margin-bottom: 0;
}

.page.law-firm-details-page .deactivate-toggle-container {
  margin: 0 var(--medium-dimension);
}

.linked-lawyer-pagination-container {
  display: flex;
  justify-content: center;
}

.linked-lawyers-table .inactive-tag {
  margin-left: 0;
}
