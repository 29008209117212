:root {
  --reports-list-controls-width: 252px;
  --reports-list-item-padding: var(--medium-dimension);
  --reports-list-checkbox-spacing: 1.25rem;
  --reports-list-checkbox-width: 1.25rem;
}

.reports-list-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: var(--medium-dimension);
}

.reports-list-date-ranges {
  display: flex;
  margin-bottom: 0;
  margin-right: var(--medium-dimension);
}

.reports-list-date-ranges > li:not(:last-child) {
  margin-right: var(--reports-list-checkbox-spacing);
}

.reports-list-date-ranges .checkbox-container {
  margin-top: 0;
}

.reports-list-date-ranges .checkbox-container > label {
  display: flex;
  flex-wrap: wrap;
  width: var(--reports-list-checkbox-width);

  & > span {
    order: 1;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  & > svg {
    order: 2;
  }
}

.reports-list-filter {
  display: flex;
  justify-content: flex-end;
  min-width: calc(
    var(--reports-list-controls-width) + var(--reports-list-item-padding)
  );
}

.reports-list-filter .dropdown-menu {
  min-width: 180px;
  margin-bottom: 0;
}

.reports-list-item {
  background-color: var(--primary-background-color);
}

.reports-list-item:nth-child(2n + 2) {
  background-color: var(--secondary-background-color);
}

.reports-list-item .expanding-card {
  margin-bottom: 0;
  overflow: visible;
}

.reports-list-item-main-content {
  display: flex;
  align-items: center;
  padding: var(--reports-list-item-padding);
}

.reports-list-item-main-content h4 {
  flex: 1;
  margin-bottom: 0;
  cursor: pointer;
}

.reports-list-item-main-content h4:hover {
  color: var(--dark-blue-grey-color);
}

.reports-list-item-expanded-content {
  padding: var(--reports-list-item-padding);
  padding-top: 0;
}

.reports-list-item-expanded-content textarea {
  width: 100%;
  resize: vertical;
}

.reports-list-item-date-ranges {
  display: flex;
  margin-right: var(--medium-dimension);
}

.reports-list-item-date-ranges .checkbox-container {
  margin-top: 0;

  &:not(:last-child) {
    margin-right: var(--reports-list-checkbox-spacing);
  }
}

.reports-list-item-date-ranges .checkbox-container > label {
  width: var(--reports-list-checkbox-width);

  & > span {
    margin-left: 0;
  }
}

.reports-list-item-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: var(--reports-list-controls-width);
}

.reports-list-item-controls > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.reports-list-item-controls .dropdown-menu {
  margin-bottom: 0;
}

.reports-list-item-controls .dropdown-menu-option {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
}

.reports-list-item-controls .button {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  line-height: 22.5px; /* matched to the modify dropdown icon height */
}

.reports-list-item-expand-button {
  @extend reset;
  @extend %default-button-colors;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:active {
    @extend %hover-button-colors;
  }
}
