:root {
  --staff-card-heading-content-color: var(--pure-white-color);
  --staff-card-user-code-max-width: 4rem;
}

.staff-card {
  background-color: var(--dark-blue-grey-color);
  border-radius: 5px;
}

.staff-card-heading {
  display: flex;
  align-items: center;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
}

.staff-card-heading h2 {
  margin-bottom: 0;
  margin-right: var(--medium-dimension);
  color: var(--staff-card-heading-content-color);

  &:first-child:not(:last-child) {
    max-width: var(--staff-card-user-code-max-width);
  }
}

.staff-card-heading .name-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.staff-card-heading-inputs {
  display: flex;
  flex: 1;
  max-width: 530px;
}

.staff-card-heading-inputs > .input-help {
  flex: 1;
  margin-right: var(--medium-dimension);
  max-width: 12rem;

  &:first-child {
    max-width: var(--staff-card-user-code-max-width);
  }
}

.staff-card-heading-inputs input[type='text'],
.staff-card-heading-inputs
  input[type='text']:focus:not(:read-only):not(:disabled),
.staff-card-heading-inputs input[type='text']::placeholder {
  @extend reset;
  @extend %heading-fonts;
  font-size: var(--h2-font-size);
  color: var(--staff-card-heading-content-color);
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--staff-card-heading-content-color);
  max-width: 100%;
}

.staff-card-heading-inputs input[type='text']:read-only {
  border-bottom-color: transparent;
}

.staff-card-heading-inputs input[type='text']::placeholder {
  opacity: 0.7;
}

.staff-card-heading .deactivate-toggle-container {
  margin-top: 0;
}

.staff-card-actions {
  display: flex;
  margin-left: var(--medium-dimension);
}

.staff-card-actions > button.button-link-appearance {
  color: var(--staff-card-heading-content-color);
}

.staff-card-actions > button.button-link-appearance:hover,
.staff-card-actions > button.button-link-appearance:focus,
.staff-card-actions > button.button-link-appearance:active {
  color: var(--staff-card-heading-content-color);
  opacity: 0.7;
}

.staff-card-actions > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.staff-card-content {
  background-color: var(--secondary-background-color);
}

.staff-card-content form {
  padding: var(--medium-dimension);
}

.staff-card-content form > .form-row > * {
  flex: 1;
}

.staff-card-form-row-subgroup {
  display: flex;
  align-items: flex-end;

  & > * {
    flex: 1;
  }
}

.staff-card-required-fields {
  display: flex;
}

.staff-card-required-fields .checkbox-container {
  margin-top: 0;
}

.staff-card-required-fields .checkbox-container:not(:last-child) {
  margin-right: var(--medium-dimension);
}

@media print {
  .staff-print-card {
    border: 1px solid var(--black-color);
    margin-bottom: var(--medium-dimension);
    padding: var(--narrow-dimension);
  }
  .staff-print-card .bold {
    font-weight: var(--bold-font-weight);
  }
}
