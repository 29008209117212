%input-colors {
  border-color: var(--input-border-color);
  background-color: var(--input-background-color);
  color: var(--input-content-color);
}

%input-fonts {
  font-family: var(--font-family);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
  line-height: var(--input-line-height);
}

%input-borders {
  border-width: var(--input-border-width);
  border-radius: var(--border-radius);
  border-style: solid;
}

%input-padding {
  padding-top: var(--input-padding-vertical);
  padding-bottom: var(--input-padding-vertical);
  padding-left: var(--input-padding-horizontal);
  padding-right: var(--input-padding-horizontal);
}

%input-readonly {
  border-color: var(--input-readonly-color);
  background-color: var(--input-readonly-color);
  color: var(--input-content-color);
  cursor: default;
}

%input-disabled {
  border-color: var(--input-disabled-color);
  background-color: var(--input-background-color);
  color: var(--input-disabled-color);
  cursor: default;
}

%input-label-content {
  font-size: var(--input-label-font-size);
  font-weight: var(--semi-bold-font-weight);
  line-height: var(--input-label-line-height);
  color: var(--input-label-color);
}
