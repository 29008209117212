.issue-cheque-client-summary {
  max-width: 80%;
}

.issue-cheque-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--medium-dimension);
}

.issue-cheque-controls button {
  padding: var(--medium-dimension) var(--wide-dimension);
}

.cheques-table tbody > tr > td:last-child {
  display: flex;
  justify-content: flex-end;
}

.new-cheque-modal {
  width: var(--layout-content-width);
  padding-left: var(--layout-inset-col-2);
  padding-right: var(--layout-inset-col-2);
}

.new-cheque-modal h3 {
  font-size: 1.25rem;
  font-weight: var(--regular-font-weight);
  margin-top: calc(var(--medium-dimension) * 2);
}

.new-cheque-modal form .form-row {
  margin-bottom: var(--medium-dimension);
}

.new-cheque-modal form .form-row:first-child {
  & > *:nth-child(2),
  & > *:nth-child(3) {
    width: 9rem;
  }
}

.new-cheque-modal form .form-row:nth-child(2) > * {
  width: 6rem;
}

.new-cheque-modal form .form-row:nth-child(4) > *:not(:first-child) {
  width: 18rem;
}

/* Applies to the firm name when the payee is a firm */
.new-cheque-modal form .form-row:nth-child(4) > *:first-child:last-child {
  width: 27.75rem;
}

.new-cheque-modal form .form-row:nth-child(5) {
  & > *:first-child {
    width: 18rem;
  }

  & > *:nth-child(2) {
    width: 9rem;
  }

  & > *:nth-child(3) {
    width: 12rem;
  }

  & > *:nth-child(5) {
    width: 6rem;
  }
}

.new-cheque-modal .overlay {
  background-color: var(--pure-white-color);
}

.new-cheque-modal .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.new-cheque-modal .button-bar > button {
  margin-bottom: 0;
}

.new-cheque-modal .button-bar > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}
