.button-input-container {
  @extend %block-element-margins;
}

.button-input-container > label {
  color: var(--medium-grey-color);
}

.button-input-group {
  display: flex;
  position: relative;
}

button.button-input-reset {
  @extend reset;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  padding-left: var(--input-padding-horizontal);
  padding-right: var(--input-padding-horizontal);
  color: var(--primary-content-color);
  cursor: pointer;
}

input[type='text'].button-input {
  padding-left: calc(20px + (var(--input-padding-horizontal) * 2));
  margin-right: var(--narrow-dimension);
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--primary-content-color);
  @extend %heading-fonts;
  font-size: var(--h1-font-size);
  max-width: 350px;
  color: var(--primary-content-color);
}

input[type='text'].button-input::placeholder {
  font-size: var(--h1-font-size);
  line-height: var(--h1-font-size);
  height: fit-content;
}

.button-input-container.canReset input[type='text'].button-input {
  padding-left: var(--medium-dimension);
}

input[type='text'].button-input:focus:not(:read-only):not(:disabled) {
  background-color: transparent;
  border-bottom-color: var(--primary-content-color);
}

button.button-input-confirm {
  min-width: 100px;
}
