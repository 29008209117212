:root {
  --inset-list-item-inset-width: var(--medium-dimension);
  --inset-list-item-inset-spacing: var(--dimension-unit);
  --inset-list-item-inset-border-width: 1px;
  --inset-list-item-inset-border-color: var(--primary-content-color);
}

li.inset-list-item {
  position: relative;
  padding-left: calc(
    var(--inset-list-item-inset-width) + var(--inset-list-item-inset-spacing)
  );
}

.list-item-inset {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--inset-list-item-inset-width);
}

.list-item-inset-inner {
  height: 50%;
  width: 50%;
  margin-left: calc(50% - var(--inset-list-item-inset-border-width));
  border-left-width: var(--inset-list-item-inset-border-width);
  border-left-style: solid;
  border-left-color: var(--primary-content-color);
  border-bottom-width: var(--inset-list-item-inset-border-width);
  border-bottom-style: solid;
  border-bottom-color: var(--primary-content-color);
}
