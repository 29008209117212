.day-end-date-container {
  @extend %block-element-margins;
}

.day-end-date-container > label {
  display: block;
  margin-bottom: var(--narrow-dimension);
  color: var(--black-color);
  font-size: 0.875rem;
  font-weight: var(--semi-bold-font-weight);
}

.day-end-date-group {
  display: flex;
  position: relative;
}

button.day-end-date-reset {
  @extend reset;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  padding-left: var(--input-padding-horizontal);
  padding-right: var(--input-padding-horizontal);
  color: var(--primary-content-color);
  cursor: pointer;
}

input.day-end-date-input {
  padding-left: calc(20px + (var(--input-padding-horizontal) * 2));
  margin-right: var(--narrow-dimension);
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--primary-content-color);
  @extend %heading-fonts;
  font-size: var(--h1-font-size);
  line-height: 1.25;
  max-width: 350px;
  color: var(--primary-content-color);
}

input[type='text'].day-end-date-input:focus:not(:read-only):not(:disabled) {
  background-color: transparent;
  border-bottom-color: var(--primary-content-color);
}

input[type='text'].day-end-date-input::placeholder {
  font-size: var(--h1-font-size);
  line-height: 1.25;
}

button.day-end-date-confirm {
  min-width: 100px;
}
