.cheque-run-results .cheque-run-failed-cheques > p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cheque-run-results .cheque-run-failed-cheques > ul {
  line-height: 1.5;
  list-style: none;
  padding: var(--medium-dimension);
  background-color: var(--primary-background-color);
}

.cheque-run-results ul.cheque-run-reports li {
  display: flex;
  justify-content: space-between;
  padding: var(--medium-dimension);
  margin-bottom: var(--medium-dimension);
  background-color: var(--primary-background-color);
}

.cheque-run-results ul.cheque-run-reports li .clipboard-button {
  font-size: var(--body-font-size);
}

.cheque-run-results ul.cheque-run-reports li a,
.cheque-run-results .download-button-bar a {
  font-size: var(--body-font-size);
  font-weight: var(--bold-font-weight);
  color: var(--highlight-color);
  text-decoration: none;
}

.cheque-run-results ul.cheque-run-reports li a:hover {
  text-decoration: underline;
}

.cheque-run-results ul.cheque-run-reports li a .fa-icon,
.cheque-run-results .download-button-bar a .fa-icon {
  font-size: 1em;
  margin-right: 0.3em;
}

.cheque-run-results .download-button-bar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: var(--narrow-dimension);
}

.cheque-run-results .button-bar {
  display: flex;
}

.cheque-run-results .button-bar > * {
  flex: 1;
}
