.action-card {
  @extend %block-element-margins;
  @extend %card-border;
  background-color: var(--card-background-color);
  overflow: hidden;
}

.action-card .action-card-header {
  display: flex;
  padding: var(--medium-dimension);
  border-bottom-width: var(--border-width);
  border-bottom-style: solid;
  border-bottom-color: var(--card-border-color);
}

.action-card .action-card-header > div:first-child {
  flex: 1;
}

.action-card .action-card-header > div:first-child > h2 {
  margin: 0;
  color: var(--action-card-heading-color);
}

.action-card .action-card-header > div:first-child > p {
  margin: 0;
  color: var(--action-card-subheading-color);
}

.action-card .action-card-header > div:last-child > * {
  margin-left: var(--medium-dimension);
}

.action-card .action-card-content {
  padding: var(--medium-dimension);
}

.action-card .action-card-footer {
  display: flex;
}

.action-card .action-card-footer > button {
  @extend reset;
  @extend %button-fonts;
  @extend %highlight-button-colors;
  flex: 1;
  padding: var(--medium-dimension);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  cursor: pointer;
}

.action-card .action-card-footer > button:focus:not(:disabled),
.action-card .action-card-footer > button:hover:not(:disabled),
.action-card .action-card-footer > button:active:not(:disabled) {
  @extend %highlight-hover-button-colors;
}

.action-card .action-card-footer > button:disabled {
  @extend %disabled-button-styles;
}
