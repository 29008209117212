.case-reassign-next-client > h3 {
  font-size: 1rem;
  font-weight: var(--semi-bold-font-weight);
  margin-bottom: 0;
}

.case-reassign-next-client .input-help {
  margin-bottom: var(--medium-dimension);

  & input[type='text'].case-number-input {
    &.case-reassign-client-id-input {
      width: 15rem;
    }

    &.case-reassign-client-code-input {
      width: 7rem;
      padding-left: var(--input-padding-horizontal);
    }
  }
}
