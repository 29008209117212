.fab {
  transition-property: height;
  transition-duration: var(--fab-transition-duration);
  transition-timing-function: var(--fab-transition-timing-function);
  background-color: var(--fab-secondary-background-color);
  border-width: var(--fab-outer-border-width);
  border-style: solid;
  border-color: var(--fab-outer-border-color);
  border-radius: var(--fab-outer-border-radius);
  overflow: hidden;
  pointer-events: auto;
}

.fab-actions {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  bottom: 0;
  transition-property: bottom;
  transition-duration: var(--fab-transition-duration);
  transition-timing-function: var(--fab-transition-timing-function);
}

.fab-position-content .fab:not(:last-child) {
  margin-right: var(--wide-dimension);
}
