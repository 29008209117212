.client-print-header h1 > span {
  display: block;

  &:first-child:not(:last-child) {
    font-size: 0.65em;
    line-height: 1.2;
  }

  &:not(:first-child) {
    font-size: 0.35em;
    line-height: 1.2;
  }
}
