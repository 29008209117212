.law-firms-list-page-heading {
  margin-top: 0;
  margin-bottom: var(--wide-dimension);
  display: flex;
}

.law-firms-list-page-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.law-firms-list-page-heading-left h1 {
  margin-bottom: 0;
}

.law-firms-list-page-search-bar {
  @extend %block-element-margins;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.law-firms-list-page-search-bar .general-search-input {
  width: 50%;
  height: 100%;
  margin-bottom: 0;
}

.law-firms-list-page-search-bar .law-firm-sort-menu {
  margin-bottom: 0;
}

.law-firms-list-page-search-bar .styled-select select {
  border-width: 2px;
  border-style: solid;
  border-color: var(--dark-blue-grey-color);
  border-radius: 3px;
  height: 100%;
  align-items: center;
  font-weight: 700;
  color: var(--dark-blue-grey-color);
}
.law-firms-list-page-search-bar .styled-select .styled-select-icon {
  color: var(--dark-blue-grey-color);
}

.law-firms-list-page-search-bar .styled-select select:hover,
.law-firms-list-page-search-bar .styled-select select:focus {
  background-color: var(--soft-blue-color);
}

.law-firms-list {
  @extend %block-element-margins;
}

.law-firms-list-pagination-container {
  display: flex;
  justify-content: center;
}
