.delete-folder-modal .button-bar,
.delete-report-modal .button-bar {
  display: flex;
  justify-content: flex-end;

  & > .button {
    margin-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-right: var(--medium-dimension);
  }
}

.report-result-modal .button-bar:not(:last-child) {
  display: flex;

  & > .button {
    width: 50%;
    margin-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-right: var(--medium-dimension);
  }
}

.report-result-modal .button-bar:last-child {
  display: flex;
  margin-top: var(--medium-dimension);

  & > .button {
    width: 100%;
    margin-bottom: 0;
  }
}
