.tag {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
  background-color: var(--light-grey-color);
  color: var(--black-color);
  border-radius: var(--border-radius);
}

.tag-success {
  background-color: var(--success-color);
  color: var(--pure-white-color);
}

.tag-warning {
  background-color: var(--warning-color);
  color: var(--black-color);
}

.tag-error {
  background-color: var(--soft-red-color);
  color: var(--muted-error-color);
}

.tag-error-inverse {
  background-color: var(--muted-error-color);
  color: var(--soft-red-color);
}

.tag-error-bold {
  background-color: var(--error-color);
  color: var(--pure-white-color);
}
