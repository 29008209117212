.disclosure .disclosure-header {
  display: flex;
  align-items: center;
}

.disclosure .disclosure-header > h2 {
  flex: 1;
  margin-bottom: 0;
}

.disclosure > .disclosure-content {
  display: none;
  margin-top: var(--medium-dimension);
}

.disclosure.disclosure-content-visible > .disclosure-content {
  display: block;
}
