.company-form {
  display: flex;
  flex-direction: column;
}

.company-form .form-row:nth-child(2) {
  & * {
    flex: 4;
  }

  & *:first-child {
    flex: 2;
  }

  & *:nth-child(2) {
    flex: 8;
  }

  & *:last-child {
    flex: 2;
  }
}

.company-form .form-row:nth-child(3) {
  & *:first-child {
    flex: 8;
  }

  & *:nth-child(2) {
    flex: 4;
  }

  & *:nth-child(3) {
    flex: 4;
  }

  & *:nth-child(4) {
    flex: 2.5;
  }

  & *:nth-child(5) {
    flex: 2.5;
  }

  & *:last-child {
    flex: 2;
  }
}

.company-form .form-row:nth-child(4) {
  & *:first-child {
    flex: 3.5;
  }

  & *:nth-child(2) {
    flex: 3.5;
  }

  & *:nth-child(3) {
    flex: 17;
  }
}

.company-form .form-row:nth-child(5) {
  & * {
    flex: 1;
  }

  & *:nth-child(2) {
    flex: 1;
  }

  & *:nth-child(3) {
    flex: 2;
  }

  & .input-label-content {
    font-weight: var(--bold-font-weight);
  }
}
