.law-firm-form {
  display: flex;
  flex-direction: column;
}

.law-firm-form > .form-row:nth-child(2) {
  & > *:first-child {
    flex: 3;
  }

  & > *:nth-child(2) {
    flex: 2;
  }

  & > *:nth-child(3) {
    flex: 2;
  }

  & > *:nth-child(4) {
    flex: 2;
  }

  & > *:last-child {
    flex: 3;
  }
}

.law-firm-form > .form-row:nth-child(3) {
  & > *:first-child {
    flex: 8;
  }

  & > *:nth-child(2) {
    flex: 6;
  }

  & > *:nth-child(3) {
    flex: 4;
  }

  & > *:nth-child(4) {
    flex: 3;
  }

  & > *:last-child {
    flex: 3;
  }
}
