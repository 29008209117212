.clipboard-button {
  @extend reset;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 0.8rem;
  font-weight: var(--bold-font-weight);
  color: var(--highlight-color);
  cursor: pointer;
}

.clipboard-button:hover > span {
  text-decoration: underline;
}

.clipboard-button > *:not(:last-child) {
  margin-right: 0.3em;
}

.clipboard-button .fa-icon {
  font-size: 1em;
}
