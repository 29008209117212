.list-card {
  @extend %card-border;
  display: flex;
}

button.list-card {
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.card.indented-list-card {
  position: relative;
  border-radius: 0;
  padding-left: calc(var(--card-padding) + var(--card-indent-width));
}

.card.indented-list-card::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--card-indent-width);
  height: 100%;
  background-color: var(--card-indent-color);
  content: '';
}

.list-card.case-list-card,
.list-card.admin-list-card {
  @extend %case-list-card-colors;
}

.list-card .list-card-content {
  flex: 1;
}

.list-card .list-card-content > h2 {
  margin: 0;
  color: var(--list-card-heading-color);
}

.list-card .list-card-content > p {
  margin: 0;
  color: var(--list-card-subheading-color);
}

.list-card .list-card-accessory {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--list-card-accessory-color);
}

.list-card:focus .list-card-accessory,
.list-card:active .list-card-accessory {
  color: var(--highlight-color);
}
