@import './filter/styles';
@import './results/styles';

.page.search .layout-column:last-child {
  flex: 2;
}

.page.search .search-pagination {
  justify-content: center;
}

.page.search .search-meta {
  display: flex;
  align-items: center;
  margin-bottom: var(--wide-dimension);
}

.page.search .search-meta > * {
  margin-bottom: 0;
}

.page.search .search-meta > h1 {
  flex: 1;
  font-size: 1.5rem;
}

.page.search .search-meta > .search-sort-menu {
  margin-left: var(--wide-dimension);
}
