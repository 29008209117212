.union-details-page-heading {
  @extend %block-element-margins;
  display: flex;
}

.union-details-page-heading-left {
  flex: 1;
  display: flex;

  flex-direction: column;
}
.union-details-page-heading-left > div {
  display: flex;
}

.union-details-page-heading-left p {
  margin-bottom: 0px;
  color: var(--medium-grey-color);
}

.union-details-page-heading-left h1 {
  margin-bottom: 0;
}

.page.union-details-page .deactivate-toggle-container {
  margin: 0 var(--medium-dimension);
}

.linked-company-pagination-container {
  display: flex;
  justify-content: center;
}

.union-details-form > .form-row:nth-child(2) {
  & > *:first-child {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 3;
  }

  & > *:nth-child(3) {
    flex: 2;
  }

  & > *:nth-child(4) {
    flex: 2;
  }

  & > *:last-child {
    flex: 2;
  }
}

.union-details-form > .form-row:nth-child(3) {
  & > *:first-child {
    flex: 6;
  }

  & > *:nth-child(2) {
    flex: 2;
  }

  & > *:nth-child(3) {
    flex: 3;
  }

  & > *:nth-child(4) {
    flex: 2;
  }

  & > *:last-child {
    flex: 2;
  }
}

@media print {
  h1.linked-unions-heading {
    font-size: 1.25rem;
  }
}
