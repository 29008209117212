:root {
  --filter-input-icon-container-width: 65px;
}

.filter-input {
  @extend %block-element-margins;
  position: relative;
}

.filter-input input {
  width: 100%;
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--filter-input-icon-container-width);
  padding-right: var(--filter-input-icon-container-width);
  background-color: var(--soft-blue-color);
}

.filter-input input::placeholder {
  color: var(--dark-grey-color);
}

.filter-input button.filter-input-reset {
  @extend reset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--filter-input-icon-container-width);
  height: 100%;
  color: var(--input-content-color);
  cursor: pointer;
}

.filter-input .filter-input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--filter-input-icon-container-width);
  height: 100%;
  color: var(--input-content-color);
  cursor: pointer;
}
