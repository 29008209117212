.lawyer-panels-columns {
  display: flex;
}

ul.lawyer-panels-column {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.lawyer-panels-column:not(:last-child) {
  margin-right: var(--wide-dimension);
}

ul.lawyer-panels-column > li {
  margin: 0;
  margin-bottom: var(--medium-dimension);
}

ul.lawyer-panels-column > li > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.lawyer-panels-column > li > ul > li {
  margin: 0;
}

ul.lawyer-panels-column .checkbox-container {
  margin: 0;
}

ul.lawyer-panels-column .checkbox-container span {
  line-height: 2rem;
}
