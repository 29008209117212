.note-modal {
  width: 520px;
}

.note-modal form textarea {
  width: 100%;
  height: 260px;
  margin-bottom: var(--medium-dimension);
}

.note-modal .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.note-modal .button-bar > button.button {
  margin-bottom: 0;
}

.note-modal .button-bar > button:not(:last-child) {
  margin-right: var(--medium-dimension);
}
