.company-print-details {
  padding: var(--medium-dimension);
  margin-bottom: var(--wide-dimension);
  background-color: var(--primary-background-color);
  -webkit-print-color-adjust: exact;
}

.company-print-details h2 {
  font-size: 1.25rem;
}

.company-print-details-row {
  display: flex;
}

.company-print-details-row:not(:last-child) {
  margin-bottom: var(--medium-dimension);
}

.company-print-details-item {
  flex: 1;
}

.company-print-details-item:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.company-print-details-row:nth-child(2) {
  & .company-print-details-item:nth-child(2),
  & .company-print-details-item:nth-child(3) {
    flex: 2;
  }

  & .company-print-details-item:nth-child(4) {
    flex: 3;
  }
}

.company-print-details-row:nth-child(4) {
  & .company-print-details-item {
    flex: 4;
  }

  & .company-print-details-item:nth-child(4) {
    flex: 5;
  }
}

.company-print-details-item > span {
  display: block;
}

.company-print-details-item > span:first-child {
  font-weight: var(--bold-font-weight);
}
