button.button,
a.button {
  @extend reset;
  @extend %button-padding;
  @extend %button-fonts;
  @extend %default-button-colors;
  border-width: var(--border-width);
  border-radius: var(--border-radius);
  border-style: solid;
  cursor: pointer;
}

a.button {
  display: inline-block;
}

button.button:not(:disabled):focus,
button.button:not(:disabled):hover,
button.button:not(:disabled):active,
a.button:focus,
a.button:hover,
a.button:active {
  @extend %hover-button-colors;
}

button.button.button-highlight,
a.button.button-highlight {
  @extend %highlight-button-colors;
}

button.button.button-highlight:not(:disabled):focus,
button.button.button-highlight:not(:disabled):hover,
button.button.button-highlight:not(:disabled):active,
a.button.button-highlight:focus,
a.button.button-highlight:hover,
a.button.button-highlight:active {
  @extend %highlight-hover-button-colors;
}

button.button:disabled {
  @extend %disabled-button-styles;
}
