.error-page .error-page-icon {
  width: 9rem;
  height: 9rem;
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.error-page .error-page-content {
  text-align: center;
}
