.page.lawyer-page ul.tabs > li.tabs-item {
  text-align: center;
  border-bottom: 0;
}

.page.lawyer-page ul.tabs > li.tabs-item > a {
  width: 100%;
}

.lawyer-page-heading {
  @extend %block-element-margins;
  display: flex;
}

.lawyer-page-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.lawyer-page-heading-left p {
  margin-bottom: 0px;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.lawyer-page-heading-left h1 {
  margin-bottom: 0;
}

.page.lawyer-page .deactivate-toggle-container {
  margin-left: var(--medium-dimension);
}
