.case-information-form > .form-row:first-child {
  & > *:first-child {
    flex: 5;
  }

  & > *:nth-child(2) {
    flex: 2;
  }

  & > *:nth-child(3) {
    flex: 8;
  }

  & > *:nth-child(4) {
    flex: 6;
  }

  & > *:last-child {
    flex: 3;
  }
}

.case-information-form > .form-row:nth-child(2) > .form-column:first-child {
  flex: 7;
}

.case-information-form > .form-row:nth-child(2) > .form-column:last-child {
  flex: 5;
}

.case-information-form
  > .form-row:nth-child(2)
  > .form-column:first-child
  > .form-row:first-child {
  & > *:first-child,
  & > *:nth-child(2) {
    flex: 1;
  }

  & > *:nth-child(3) {
    flex: 2;
  }
}

.case-information-form
  > .form-row:nth-child(2)
  > .form-column:first-child
  > .form-row:last-child
  > * {
  & > *:first-child,
  & > *:nth-child(2) {
    flex: 1.2;
  }

  & > *:nth-child(3),
  & > *:nth-child(4) {
    flex: 1;
  }
}

.case-information-form
  > .form-row:nth-child(2)
  > .form-column:last-child
  > .form-row {
  align-items: stretch;

  & > *:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & textarea {
    flex: 1;
  }
}

.case-page-content .client-summary .client-summary-heading {
  align-items: flex-start;
}
.case-page-content .client-summary .client-summary-heading .edit-client-button {
  background: none;
  border: none;
  position: relative;
  left: calc(var(--wide-dimension) + 1rem);
  cursor: pointer;
}

.update-company-modal {
  min-width: 700px;
}

.update-company-modal .button-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--medium-dimension);
}

.update-company-modal .button-bar button {
  margin-left: var(--narrow-dimension);
  margin-bottom: 0px;
}
