.print-line {
  display: flex;
  align-items: center;
  width: 100%;
}

.print-line > * {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
