:root {
  --layout-content-width: 1440px;
  --layout-header-height: 90px;
  --layout-implied-columns: 12;
  --layout-implied-column-width: calc(100% / var(--layout-implied-columns));
  --layout-inset-col-1: var(--layout-implied-column-width);
  --layout-inset-col-2: calc(var(--layout-implied-column-width) * 2);
}

.layout-container {
  display: flex;
  width: var(--layout-content-width);
  margin: 0 auto;
  padding: var(--wide-dimension);
}

.layout-container:not(.layout-container-no-offset) {
  padding-top: 0;
}

.layout-container:not(.layout-container-no-offset):first-child {
  padding-top: calc(var(--layout-header-height) + var(--wide-dimension));
}

.layout-container.layout-container-full-width {
  max-width: 100%;
}

.layout-container .layout-column {
  flex: 1;
}

.layout-container .layout-column:not(:first-child) {
  margin-left: calc(var(--wide-dimension) / 2);
}

.layout-container .layout-column:not(:last-child) {
  margin-right: calc(var(--wide-dimension) / 2);
}

.layout-container.inset-col-1 .layout-column:first-child {
  margin-left: var(--layout-inset-col-1);
}

.layout-container.inset-col-1 .layout-column:last-child {
  margin-right: var(--layout-inset-col-1);
}

.layout-container.inset-col-2 .layout-column:first-child {
  margin-left: var(--layout-inset-col-2);
}

.layout-container.inset-col-2 .layout-column:last-child {
  margin-right: var(--layout-inset-col-2);
}
