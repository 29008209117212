/* Please read the print-layouts-README file before editing the print layouts. */

.cif-print-gutter {
  height: 1.64%; /* 15px */
}

.cif-print-section-header {
  display: flex;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--medium-grey-color);
}

.cif-print-section-header > *:not(:first-child) {
  flex: 1;
  margin-left: var(--dimension-unit);
  border-bottom: 1px solid var(--medium-grey-color);
}

.cif-print-tickbox {
  height: 10px;
  width: 10px;
  margin-right: 3px;
  border: 1px solid var(--primary-content-color);
  border-radius: 50%;
}

.cif-print-tickbox.ticked {
  background: var(--primary-content-color);
  -webkit-print-color-adjust: exact;
}

.cif-print-date-placeholder {
  display: flex;
  justify-content: space-around;
}

/* Header */

.cif-print-header {
  display: flex;
  align-items: flex-end;
  height: 6.14%; /* 58px */
  overflow: hidden;
}

.cif-print-header-content {
  width: 80%;
  height: 100%;
}

.cif-print-header-content > .print-line:first-child {
  color: var(--light-grey-color);
  height: 29.3%; /*17px*/
  display: block;
}

.cif-print-header-content > .print-line:not(:nth-child(2)) {
  height: 29.3%; /* 17px */

  & > * {
    font-size: 12px;
  }
}

.cif-print-header-content > .print-line:nth-child(2) {
  height: 41.4%; /* 24px */

  & > h1 {
    margin: 0;
    font-weight: var(--bold-font-weight);
    font-size: 18px;
  }
}

.cif-print-layout p.referralBenefit {
  font-weight: var(--bold-font-weight);
  position: relative;
  margin: 0;
  margin-top: -1rem;
  top: 2.8125rem;
  text-align: center;
  font-size: 1rem;
}

ul.cif-print-header-metadata {
  width: 20%;
  margin: 0;
  font-size: 10px;
  text-align: right;
}

/* Part A */

.cif-print-part-a {
  height: 23.24%; /* 212px */
}

.cif-print-part-a-gutter {
  height: 4.71%; /* 10px */
}

.cif-print-part-a-header {
  height: 6.6%; /* 14px */
}

.cif-print-part-a-participant {
  display: flex;
  height: 62.73%; /* 133 px */
}

.cif-print-part-a-participant-primary {
  width: 60%;
  padding-right: 6%; /* 40px */
}

.cif-print-part-a-participant-secondary {
  width: 40%;
  padding-left: 6%; /* 40px */
  border-left: 1px solid var(--medium-grey-color);
}

.cif-print-part-a-participant-status {
  height: 12.78%; /* 17px */
  font-size: 12px;
}

h2.cif-print-part-a-participant-name {
  height: 16.54%; /* 24px */
  margin: 0;
  font-size: 16px;
  font-weight: var(--bold-font-weight);
}

.cif-print-part-a-participant-secondary h2.cif-print-part-a-participant-name {
  height: auto;
  min-height: 16.54%; /* 24px */
  max-height: 48px; /* 48px allows up to two lines at normal scale, which will push the remaining dependent info down towards the special instructions text but won't cause it to overlap. */
  text-overflow: unset;
  word-wrap: break-word;
  white-space: normal;
  height: auto;
}

.cif-print-part-a-metadata {
  display: flex;
  height: 63.15%; /* 84px */
  font-size: 10px;
}

.cif-print-part-a-metadata > ul {
  flex: 1;
  height: 100%;
  margin: 0;
}

.cif-print-part-a-metadata > ul:not(:first-child) {
  padding-left: var(--narrow-dimension);
}

.cif-print-part-a-metadata > ul:not(:last-child) {
  padding-right: var(--narrow-dimension);
}

.cif-print-part-a-metadata > ul > li {
  height: calc(100% / 6); /* Max 6 lines per metadata list */
}

.cif-print-part-a-instructions {
  display: flex;
  flex-direction: column;
  height: 21.22%; /* 45 px */
}

.cif-print-part-a-instructions > h3 {
  margin: 0;
  font-size: 12px;
  font-weight: var(--regular-font-weight);
  line-height: 17px;
}

.cif-print-part-a-instructions > p {
  flex: 1;
  margin: 0;
  font-size: 10px;
  font-weight: var(--regular-font-weight);
  overflow: hidden;
}

/* Part B */

.cif-print-part-b {
  height: 24.45%; /* 223px */
}

.cif-print-part-b-gutter {
  height: 4.48%; /* 10px */
}

.cif-print-part-b-header {
  height: 6.27%; /* 14px */
}

.cif-print-part-b-metadata {
  display: flex;
  align-items: flex-end;
  /* height: 17.48%; set as 39px */
  border-bottom: 1px solid var(--medium-grey-color);
  padding-bottom: var(--narrow-dimension);
}

.cif-print-part-b-metadata > ul {
  width: 40%;
  margin: 0;
}

.cif-print-part-b-metadata > ul:not(:first-child) {
  padding-left: var(--narrow-dimension);
}

.cif-print-part-b-metadata > ul:not(:last-child) {
  padding-right: var(--narrow-dimension);
}

.cif-print-part-b-metadata > ul:first-child {
  height: 100%;
}

.cif-print-part-b-metadata > ul:first-child > .print-line:first-child {
  height: 56.4%; /* 22px */

  & > h2 {
    margin: 0;
    font-size: 16px;
    font-weight: var(--bold-font-weight);
  }
}

.cif-print-part-b-metadata > ul:first-child > .print-line:last-child {
  height: 43.6%; /* 17px */

  & > span {
    font-size: 12px;
  }
}

.cif-print-part-b-metadata > ul:not(:first-child) {
  height: 71.79%; /* 28px */
  margin-bottom: 1px;
}

.cif-print-part-b-metadata > ul:not(:first-child) > .print-line {
  height: 50%; /* 14px */

  & > span {
    font-size: 10px;
  }
}

.cif-print-part-b-metadata > ul:last-child {
  width: 20%;
}

.cif-print-part-b-lawyer-activity {
  display: flex;
  height: 67.26%; /* 150px */
}

.cif-print-part-b-lawyer-activity-heading {
  height: 9.33%; /* 14px */
  font-size: 10px;
}

.cif-print-part-b-lawyer-activity-gutter {
  height: 6.66%; /* 10px */
}

.cif-print-part-b-lawyer-services {
  width: 40%;
  height: 100%;
  padding-right: var(--narrow-dimension);
}

.cif-print-part-b-lawyer-services > ul {
  height: 84%; /* 126px */
  margin: 0;
  font-size: 10px;
}

.cif-print-part-b-lawyer-services > ul > li {
  display: flex;
  align-items: center;
  height: 11.11%; /* 14px */

  &.cif-print-part-b-lawyer-services-list-gutter {
    height: 5.55%; /* 7px */
  }
}

.cif-print-part-b-legal-problem {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding-left: var(--narrow-dimension);
}

.cif-print-part-b-legal-problem > ul {
  flex: 1;
  margin: 0;
}

.cif-print-part-b-legal-problem > ul > li {
  /* 5 lines with compensation for bottom margin */
  height: calc((100% / 5) - 2px);
  margin-bottom: 2px;
  border-bottom: 1px solid var(--light-grey-color);
}

.cif-print-part-b ~ .cif-print-gutter {
  height: 2.74%; /* 25px */
}

/* Part C */

.cif-print-part-c {
  height: 17.87%; /* 163px */
}

.cif-print-part-c-header {
  height: 8.58%; /* 14px */
}

.cif-print-part-c-gutter {
  height: 12.26%; /* 20px */
}

.cif-print-part-c-billing-activity {
  display: flex;
  height: 78.14%; /* 129px */
}

.cif-print-part-c-billing-activity-heading {
  height: 11.64%; /* 14px */
  font-size: 10px;
}

.cif-print-part-c-billing-activity-gutter {
  height: 3.06%; /* 5px */
}

.cif-print-part-c-billing-activity-plan {
  width: 50%;
  height: 100%;
  padding-right: var(--wide-dimension);
}

.cif-print-part-c-billing-activity-client {
  width: 50%;
  height: 100%;
  padding-left: var(--wide-dimension);
}

.cif-print-part-c-billing-activity-table {
  display: flex;
  flex-direction: column;
  height: 85.27%; /* 110px */
  font-size: 10px;
}

.cif-print-part-c-billing-activity-table-header {
  display: flex;
  height: 18.18%; /* 20px */
  border-top: 1px solid var(--light-grey-color);
  border-bottom: 1px solid var(--light-grey-color);
}

.cif-print-part-c-billing-activity-table-header > * {
  display: flex;
  align-items: center;
  width: 25%;
  height: 100%;
  padding-left: var(--dimension-unit);
  padding-right: var(--dimension-unit);
  border-left: 1px solid var(--light-grey-color);
}

.cif-print-part-c-billing-activity-table-header > *:last-child {
  border-right: 1px solid var(--light-grey-color);
}

.cif-print-part-c-billing-activity-table-header > *:nth-child(2) {
  width: 50%;
  height: 100%;
}

.cif-print-part-c-billing-activity-table-body {
  flex: 1;
}

.cif-print-part-c-billing-activity-table-row {
  display: flex;
  height: calc(100% / 3); /* 3 rows */
}

.cif-print-part-c-billing-activity-table-row > * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cif-print-part-c-billing-activity-table-row div.text-container {
  padding-left: var(--dimension-unit);
  padding-right: var(--dimension-unit);
}

.cif-print-part-c-billing-activity-table-row div.text-container:last-child {
  justify-content: flex-end;
  padding-right: var(--narrow-dimension);
}

.cif-print-part-c-billing-activity-table-row:first-child {
  border-bottom: 1px solid var(--light-grey-color);
}

.cif-print-part-c-billing-activity-table-row:first-child > * {
  width: 25%;
  border-left: 1px solid var(--light-grey-color);
}

.cif-print-part-c-billing-activity-table-row:first-child > *:last-child {
  border-right: 1px solid var(--light-grey-color);
}

.cif-print-part-c-billing-activity-table-row:first-child > *:nth-child(2) {
  width: 50%;
}

.cif-print-part-c-billing-activity-table-row:not(:first-child) > *:first-child {
  width: 50%;
  font-size: 8px;
}

.cif-print-part-c-billing-activity-table-row:not(:first-child)
  > *:nth-child(2) {
  justify-content: flex-end;
  width: 25%;
  padding-left: var(--dimension-unit);
  padding-right: var(--dimension-unit);
}

.cif-print-part-c-billing-activity-table-row:not(:first-child) > *:last-child {
  width: 25%;
  border: 1px solid var(--light-grey-color);
  border-top: 0;
}

/* Part D (Office/Certification) */

.cif-print-part-d {
  height: 22.04%; /* 201px */
}

.cif-print-part-d-header {
  height: 6.97%; /* 14px */
}

.cif-print-part-d-gutter {
  height: 2.98%; /* 6px */
}

.cif-print-part-d-content {
  display: flex;
  height: 90%; /* 181px */
}

.cif-print-part-d-content-details {
  width: 54%;
  height: 100%;
  border: 1px solid var(--light-grey-color);
  font-size: 10px;
  padding-right: var(--narrow-dimension);
}

.cif-print-part-d-content-details-gutter {
  height: 3.86%; /* 7px */
}

.cif-print-part-d-content-details-gutter-wide {
  height: 7.73%; /* 14px */
}

.cif-print-part-d-content-details-row {
  display: flex;
  height: 11%; /* 20px */
}

.cif-print-part-d-content-details-field,
.cif-print-part-d-content-details-tickbox {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  padding-left: var(--narrow-dimension);
  padding-right: var(--narrow-dimension);
}

.cif-print-part-d-content-details-field:first-child,
.cif-print-part-d-content-details-tickbox:first-child {
  padding-right: var(--medium-dimension);
}

.cif-print-part-d-content-details-field:last-child:not(:first-child),
.cif-print-part-d-content-details-tickbox:last-child:not(:first-child) {
  padding-left: var(--medium-dimension);
}

.cif-print-part-d-content-details-field > *:first-child {
  width: 50%;
}

.cif-print-part-d-content-details-field > *:last-child {
  width: 50%;
  height: 100%;
}

.cif-print-part-d-content-details-tickbox-row {
  width: 100%;
  height: 17.91%; /* 36px */
  padding-left: var(--narrow-dimension);
  padding-right: var(--narrow-dimension);
}

.cif-print-part-d-content-details-tickbox-group {
  display: flex;
  align-items: center;

  & > * {
    display: flex;
    align-items: center;
  }

  & > *:not(:last-child) {
    margin-right: var(--medium-dimension);
  }
}

.cif-print-part-d-content-certification {
  width: 46%;
  height: 100%;
  padding-left: var(--medium-dimension);
  font-size: 10px;
}

.cif-print-part-d-content-certification-header {
  height: 7.73%; /* 14px */
}

.cif-print-part-d-content-certification-message {
  height: 30.93%; /* 56px */
  margin: 0;
}

.cif-print-part-d-content-certification-signature {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 26.51%; /* 48px */

  & > div:first-child {
    height: 64.58%; /* 31px */
    border-bottom: 1px solid var(--light-grey-color);
  }

  & > div:last-child {
    height: 29.16%; /* 14px */
  }
}

.cif-print-part-d-content-certification-gutter {
  height: 7.73%; /* 14px */
}

.cif-print-part-d-content-certification-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20.44%; /* 37px */

  & > div:first-child {
    height: 54.05%; /* 20px */
    border-bottom: 1px solid var(--light-grey-color);
  }

  & > div:last-child {
    height: 37.83%; /* 14px */
  }
}
