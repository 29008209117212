input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='tel'],
textarea {
  @extend reset;
  @extend %input-colors;
  @extend %input-fonts;
  @extend %input-borders;
  @extend %input-padding;
}

input[type='text']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder,
input[type='email']::placeholder,
input[type='tel']::placeholder {
  @extend %input-fonts;
  color: var(--medium-grey-color);
}

input[type='text']:read-only,
input[type='number']:read-only,
input[type='password']:read-only,
input[type='email']:read-only,
input[type='tel']:read-only,
textarea:read-only {
  @extend %input-readonly;
}

input[type='text']:read-only::placeholder,
input[type='number']:read-only::placeholder,
input[type='password']:read-only::placeholder,
input[type='email']:read-only::placeholder,
input[type='tel']:read-only::placeholder {
  color: transparent;
}

input[type='text']:disabled,
input[type='number']:disabled,
input[type='password']:disabled,
input[type='email']:disabled,
input[type='tel']:disabled,
textarea:disabled {
  @extend %input-disabled;
}

input[type='text']:disabled::placeholder,
input[type='number']:disabled::placeholder,
input[type='password']:disabled::placeholder,
input[type='email']:disabled::placeholder,
input[type='tel']:disabled::placeholder {
  color: transparent;
}

input[type='text']:focus:not(:read-only):not(:disabled),
input[type='number']:focus:not(:read-only):not(:disabled),
input[type='password']:focus:not(:read-only):not(:disabled),
input[type='email']:focus:not(:read-only):not(:disabled),
input[type='tel']:focus:not(:read-only):not(:disabled),
textarea:focus:not(:read-only):not(:disabled) {
  border-color: var(--highlight-color);
  background-color: var(--soft-blue-color);
}

textarea {
  resize: none;
}
