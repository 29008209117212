.date-picker-input-container {
  position: relative;
}

input.date-picker-input {
  padding-right: var(--date-picker-icon-container-width);
}

button.date-picker-input-icon {
  @extend reset;
  position: absolute;
  top: var(--input-border-width);
  right: var(--input-border-width);
  width: calc(
    var(--date-picker-icon-container-width) - var(--input-border-width)
  );
  height: calc(100% - (var(--input-border-width) * 2));
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

button.date-picker-input-icon:focus {
  background-color: var(--soft-blue-color);
}

input.date-picker-input:disabled + .date-picker-input-icon {
  color: var(--input-disabled-color);
}

/* stylelint-disable selector-class-pattern */

.react-datepicker {
  font-family: var(--font-family);
  font-size: var(--body-font-size);
  color: var(--black-color);
  box-shadow: 0 0 var(--narrow-dimension) var(--shadow-color);
}

.react-datepicker__header {
  background-color: var(--primary-background-color);
}

.react-datepicker__navigation--previous {
  border-right-color: var(--black-color);

  &:hover {
    border-right-color: var(--medium-grey-color);
  }
}

.react-datepicker__navigation--next {
  border-left-color: var(--black-color);

  &:hover {
    border-left-color: var(--medium-grey-color);
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--input-content-color);
  font-weight: var(--bold-font-weight);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--input-content-color);
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  color: var(--input-content-color);
  font-weight: var(--bold-font-weight);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--primary-background-color);
  color: var(--input-content-color);
  border-radius: var(--date-picker-calendar-day-highlight-radius);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--highlight-color);
  color: var(--pure-white-color);
  border-radius: var(--date-picker-calendar-day-highlight-radius);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary-background-color);
  color: var(--input-content-color);
  border-radius: var(--date-picker-calendar-day-highlight-radius);
}

/* stylelint-enable selector-class-pattern */
