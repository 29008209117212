.strikethrough-input {
  @extend %input-colors;
  @extend %input-fonts;
  @extend %input-borders;
  @extend %input-padding;
  @extend %input-readonly;
  width: 100%;
  height: var(--input-calculated-height);
}

.strikethrough-input-text {
  display: inline-block;
}

.strikethrough-input-text.struck {
  text-decoration: line-through;
}

.strikethrough-input-delimiter {
  margin-right: var(--narrow-dimension);
}

.strikethrough-input-delimiter:last-child {
  display: none;
}
