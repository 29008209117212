:root {
  --dropdown-menu-background-color: var(--pure-white-color);
  --dropdown-menu-content-color: var(--dark-blue-grey-color);
  --dropdown-menu-selected-background-color: var(--dark-blue-grey-color);
  --dropdown-menu-selected-content-color: var(--pure-white-color);
  --dropdown-menu-hover-background-color: var(--soft-blue-color);
  --dropdown-menu-border-color: var(--dark-blue-grey-color);
  --dropdown-menu-option-border-color: var(--soft-blue-color);
}

.dropdown-menu {
  @extend %block-element-margins;
  position: relative;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-width: var(--border-width);
  border-style: solid;
  border-color: var(--dropdown-menu-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-menu-background-color);
  z-index: 100;
}

.dropdown-menu.dropdown-menu-open ul.dropdown-menu-selection {
  visibility: hidden;
}

.dropdown-menu ul.dropdown-menu-options {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.dropdown-menu.dropdown-menu-open ul.dropdown-menu-options {
  display: block;
}

.dropdown-menu .dropdown-menu-option {
  display: flex;
  align-items: center;
  padding: var(--medium-dimension) var(--narrow-dimension);
  cursor: pointer;
  font-family: var(--font-family);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
  color: var(--dropdown-menu-content-color);
}

.dropdown-menu .dropdown-menu-option:not(:last-child) {
  border-bottom-width: var(--border-width);
  border-bottom-style: solid;
  border-bottom-color: var(--dropdown-menu-option-border-color);
}

.dropdown-menu .dropdown-menu-option:hover,
.dropdown-menu .dropdown-menu-option:focus {
  background-color: var(--dropdown-menu-hover-background-color);
  outline: none;
}

.dropdown-menu
  ul.dropdown-menu-options
  .dropdown-menu-option.dropdown-menu-option-selected {
  background-color: var(--dropdown-menu-selected-background-color);
  color: var(--dropdown-menu-selected-content-color);
  font-weight: var(--bold-font-weight);
}

.dropdown-menu .dropdown-menu-option-content {
  flex: 1;
  display: flex;
  align-items: center;
}
.dropdown-menu .dropdown-menu-option:first-of-type {
  font-weight: var(--bold-font-weight);
}

.dropdown-menu .dropdown-menu-option-action {
  display: none;
  margin-left: var(--medium-dimension);
  /* vertical offset for chevron icons: */
  margin-top: 2px;
  margin-bottom: -2px;
}

.dropdown-menu:not(.dropdown-menu-open)
  .dropdown-menu-option:first-child
  .dropdown-menu-option-action-open,
.dropdown-menu.dropdown-menu-open
  .dropdown-menu-option:first-child
  .dropdown-menu-option-action-close {
  display: block;
}
