* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

body,
html {
  background-color: var(--primary-background-color);
  color: var(--primary-content-color);
}

@media print {
  body,
  html {
    background-color: transparent;
    margin: 0;
    max-height: 100vh;
    max-width: 100vw;
  }
}
