:root {
  --page-action-button-width: 180px;
}

.button.page-action-button {
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--wide-dimension);
  padding-right: var(--wide-dimension);
  min-width: var(--page-action-button-width);
}

.button.page-action-button:not(:last-child) {
  margin-right: var(--medium-dimension);
}
