button.version-info-button {
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: underline;
}

.version-info-modal {
  min-width: 480px;
  max-width: calc(100vw - (var(--medium-dimension) * 2));
}

.version-info-modal-content > h3 {
  margin-bottom: var(--narrow-dimension);
}

.version-info-modal-content > ul {
  list-style: none;
  padding: 0;

  & > li > span {
    word-break: break-word;
  }
}

.version-info-modal-credit {
  display: flex;
  align-items: center;
  justify-content: center;
}
