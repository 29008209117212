button.table-sort-button {
  @extend reset;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: var(--body-font-size);
  font-weight: var(--bold-font-weight);
  color: var(--table-header-content-color);
  cursor: pointer;
}

button.table-sort-button:disabled {
  cursor: default;
}

button.table-sort-button .fa-icon {
  margin-left: var(--narrow-dimension);
  opacity: 0.6;
}

button.table-sort-button-active .fa-icon {
  opacity: 1;
}

@media print {
  button.table-sort-button {
    font-size: var(--table-print-font-size);
    color: var(--primary-content-color);
  }
}
