table.sortable-table.sortable-table-rows-clickable tbody tr {
  cursor: pointer;
  border-width: var(--border-width);
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
  border-bottom: none;
}
table.sortable-table.sortable-table-rows-clickable tbody tr:last-of-type {
  border-bottom: var(--border-width) solid rgba(255, 255, 255, 0);
}

table.sortable-table.sortable-table-rows-clickable tbody tr:hover {
  background-color: var(--table-row-hover-background-color);
  color: var(--table-row-hover-content-color);
  border-width: var(--border-width);
  border-style: solid;
  border-color: var(--table-row-hover-border-color);
}

table.sortable-table.sortable-table-rows-clickable thead th:last-child,
table.sortable-table.sortable-table-rows-clickable tbody td:last-child {
  width: calc(1rem + var(--medium-dimension));
  padding: 0;
}

table.sortable-table.sortable-table-rows-clickable
  tbody
  td:last-child
  .sortable-table-row-accessory {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: var(--medium-dimension);
}

table.sortable-table.sortable-table-rows-clickable
  tbody
  td:last-child
  .sortable-table-row-accessory
  * {
  visibility: hidden;
}

table.sortable-table.sortable-table-rows-clickable
  tbody
  tr:hover
  td:last-child
  .sortable-table-row-accessory
  * {
  visibility: visible;
}
