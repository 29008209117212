.page.case-notes .case-notes-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--medium-dimension);
}

.page.case-notes .case-notes-actions .notes-sort-menu {
  max-width: 260px;
  margin-bottom: 0;
}

.page.case-notes .case-notes-actions .client-notes-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--medium-dimension);
}

.page.case-notes .case-notes-actions .client-notes-link .fa-icon {
  margin-left: var(--medium-dimension);
}
