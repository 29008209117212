:root {
  --tabs-background-color: transparent;
  --tabs-active-background-color: var(--highlight-color);
  --tabs-hover-background-color: var(--pure-white-color);

  --tabs-content-color: var(--primary-content-color);
  --tabs-active-content-color: var(--pure-white-color);
  --tabs-hover-content-color: var(--highlight-color);

  --tabs-border-color: var(--highlight-color);

  --tabs-item-min-width: 220px;
  --min-tabs-min-width: 185px;
}

ul.tabs {
  @extend %block-element-margins;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border-bottom-width: var(--border-width);
  border-bottom-style: solid;
  border-bottom-color: var(--tabs-border-color);
}

ul.tabs > li.tabs-item {
  min-width: var(--tabs-item-min-width);
  font-size: 1.25rem;
  font-weight: var(--regular-font-weight);
  background-color: var(--tabs-background-color);
  color: var(--tabs-content-color);
}

ul.tabs > li.tabs-item.tabs-item-active {
  font-weight: var(--bold-font-weight);
  background-color: var(--tabs-active-background-color);
  color: var(--tabs-active-content-color);
}

ul.tabs > li.tabs-item > a,
ul.tabs > li.tabs-item > span {
  display: inline-block;
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--wide-dimension);
  padding-right: var(--wide-dimension);
  color: var(--tabs-content-color);
  text-align: center;
  text-decoration: none;
  border: 2px solid transparent;
  border-bottom: 0;
}

ul.tabs > li.tabs-item.tabs-item-active > a {
  color: var(--tabs-active-content-color);
  cursor: default;
  outline: none;

  &:focus {
    text-decoration: underline;
  }
}

ul.tabs > li.tabs-item:not(.tabs-item-active) > a:focus,
ul.tabs > li.tabs-item:not(.tabs-item-active) > a:hover,
ul.tabs > li.tabs-item:not(.tabs-item-active) > a:active {
  background-color: var(--tabs-hover-background-color);
  color: var(--tabs-hover-content-color);
  border-color: var(--tabs-border-color);
  outline: none;
}

ul.tabs.disabled > li.tabs-item > span {
  cursor: not-allowed;
  color: inherit;
}
