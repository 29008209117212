%button-padding {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
}

%button-fonts {
  font-family: var(--font-family);
  font-weight: var(--bold-font-weight);
  font-size: var(--body-font-size);
  line-height: 1;
  text-align: center;
}

%default-button-colors {
  background-color: var(--pure-white-color);
  border-color: var(--dark-blue-grey-color);
  color: var(--dark-blue-grey-color);
}

%hover-button-colors {
  background-color: var(--soft-blue-color);
  border-color: var(--hover-blue-color);
  color: var(--hover-blue-color);
}

%highlight-button-colors {
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
  color: var(--pure-white-color);
}

%highlight-hover-button-colors {
  background-color: var(--hover-blue-color);
  border-color: var(--hover-blue-color);
  color: var(--pure-white-color);
}

%disabled-button-styles {
  opacity: 0.3;
  cursor: default;
}
