.law-firm-details-print-view-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.law-firm-details-print-view-header h1 span {
  display: inline-block;
}

.law-firm-details-print-view-header h1 span:last-child {
  margin-left: var(--medium-dimension);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
  text-transform: uppercase;
  color: var(--medium-grey-color);
}

.law-firm-details-print-view-contact {
  padding: var(--medium-dimension);
  margin-bottom: var(--wide-dimension);
  background-color: var(--primary-background-color);
  -webkit-print-color-adjust: exact;
}

@media screen {
  .law-firm-details-print-view-contact {
    padding-left: 0;
    padding-right: 0;
  }
}

@media print {
  .law-firm-details-print-view-contact h2 {
    font-size: 1.25rem;
  }
}

.law-firm-details-print-view-contact-row {
  display: flex;
}

.law-firm-details-print-view-contact-row:not(:last-child) {
  margin-bottom: var(--medium-dimension);
}

.law-firm-details-print-view-contact-item {
  flex: 1;
}

.law-firm-details-print-view-contact-item:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.law-firm-details-print-view-contact-item > span {
  display: block;
}

.law-firm-details-print-view-contact-item > span:first-child {
  font-weight: var(--bold-font-weight);
}
