.input-help .input-help-content {
  font-size: var(--input-help-font-size);
  font-weight: var(--regular-font-weight);
  line-height: var(--input-help-line-height);
  color: var(--input-help-text-color);
}

.input-help.input-help-invalid .input-help-content {
  font-weight: var(--bold-font-weight);
  color: var(--input-help-invalid-color);
}

.input-help.input-help-disabled .input-help-content {
  color: var(--input-disabled-color);
}

.input-help.input-help-invalid input[type='text'],
.input-help.input-help-invalid input[type='number'],
.input-help.input-help-invalid input[type='password'],
.input-help.input-help-invalid input[type='email'],
.input-help.input-help-invalid input[type='tel'],
.input-help.input-help-invalid select {
  border-color: var(--input-help-invalid-color);
}
