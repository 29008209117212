@media screen {
  .lawyer-print-view {
    padding: var(--medium-dimension);
  }
}

.lawyer-print-view-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.lawyer-print-view-header h1 span {
  display: inline-block;
}

.lawyer-print-view-header h1 span:last-child {
  margin-left: var(--medium-dimension);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
  text-transform: uppercase;
  color: var(--medium-grey-color);
}

.lawyer-print-view-details {
  padding: var(--medium-dimension);
  margin-bottom: var(--wide-dimension);
  background-color: var(--primary-background-color);
  -webkit-print-color-adjust: exact;
  page-break-inside: avoid;
}

.company-print-details h2 {
  font-size: 1.25rem;
}

.lawyer-print-view-details-row {
  display: flex;
}

.lawyer-print-view-details-row:not(:last-child) {
  margin-bottom: var(--medium-dimension);
}

.lawyer-print-view-details-item {
  flex: 1;
}

.lawyer-print-view-details-item:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.lawyer-print-view-details-item > span {
  display: block;
}

.lawyer-print-view-details-item > span:first-child {
  font-weight: var(--bold-font-weight);
}

.lawyer-print-view-panels {
  page-break-inside: avoid;
}

.lawyer-print-view-panels-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--medium-dimension);

  & > ul > li > ul {
    margin-left: var(--medium-dimension);
  }
}
