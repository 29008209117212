:root {
  --search-input-height: calc(
    var(--layout-header-height) - (var(--medium-dimension) * 2)
  );
  --search-input-bottom-border-width: 2px;
}

.search-input {
  max-width: 100%;
}

.search-input .search-input-content {
  display: flex;
  align-items: center;
  height: var(--search-input-height);
}

.dropdown-menu-selection.disabled {
  border-color: var(--input-disabled-color);
}

.search-input .search-input-content .button {
  height: calc(
    var(--search-input-height) + var(--search-input-bottom-border-width)
  );
  padding-left: var(--wide-dimension);
  padding-right: var(--wide-dimension);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.search-input .search-input-wrapper {
  display: flex;
  background-color: var(--soft-blue-color);
  border-bottom-width: var(--search-input-bottom-border-width);
  border-bottom-style: solid;
  border-bottom-color: var(--highlight-color);
}

.search-input .search-input-wrapper input {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: 0;
  padding-right: 0;
  margin-left: var(--wide-dimension);
}

.search-input .search-input-wrapper input:last-child {
  margin-right: var(--wide-dimension);
}

.search-input .search-input-wrapper input::placeholder {
  color: var(--dark-grey-color);
}

.search-input .dropdown-menu {
  min-width: 11rem;
  margin-bottom: 0;
}

.search-input .dropdown-menu .dropdown-menu-option {
  height: var(--search-input-height);
}

.search-input .dropdown-menu ul.dropdown-menu-selection {
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

.search-input .dropdown-menu ul.dropdown-menu-selection .dropdown-menu-option {
  color: var(--highlight-color);
}

.search-input
  .dropdown-menu
  ul.dropdown-menu-selection
  .dropdown-menu-option:focus {
  color: var(--dark-blue-grey-color);
  text-decoration: underline;
}

.search-input ul.dropdown-menu-options {
  border-color: var(--highlight-color);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.search-input ul.dropdown-menu-options .dropdown-menu-option {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  color: var(--highlight-color);
}

.search-input
  ul.dropdown-menu-options
  .dropdown-menu-option.dropdown-menu-option-selected {
  color: var(--pure-white-color);
  background-color: var(--highlight-color);
  border-bottom-color: var(--highlight-color);
}

.dropdown-menu-option.disabled {
  color: var(--input-disabled-color);
}

/* Subject-specific inputs: */

.search-names-input {
  display: flex;

  & input:first-child {
    width: 9rem;
  }

  & input:last-child {
    width: 12rem;
  }
}

.search-client-id-input {
  display: flex;

  & input:first-child {
    width: 6rem;
  }

  & input:last-child {
    width: 2rem;
  }
}

.search-lawyer-id-input {
  display: flex;

  & input {
    width: 5rem;
  }
}

.search-case-number-input {
  display: flex;

  & input {
    width: 9rem;
  }
}
