.lawyer-information-form > .form-row:nth-child(2) {
  & > *:first-child {
    flex: 3;
  }

  & > *:nth-child(2) {
    flex: 6;
  }

  & > *:nth-child(3) {
    flex: 6;
  }

  & > *:nth-child(4) {
    flex: 4;
  }
  & > *:nth-child(5) {
    flex: 4;
  }
  & > *:nth-child(6) {
    flex: 5;
  }
}

.lawyer-information-form
  > .form-row:nth-child(3)
  > .form-column:first-child
  > .form-row:first-child {
  & > *:first-child {
    flex: 4;
  }

  & > *:nth-child(2) {
    flex: 4;
  }

  & > *:nth-child(3) {
    flex: 4;
  }

  & > *:nth-child(4) {
    flex: 8;
  }
}

.lawyer-information-form .language-container {
  display: flex;
}
.lawyer-information-form .language-container .checkbox-container {
  margin-top: 0;
  margin-right: var(--medium-dimension);
}

.lawyer-information-form
  > .form-row:nth-child(3)
  > .form-column:first-child
  > .form-row:last-child {
  & > *:first-child .input-label {
    display: flex;
    flex-wrap: wrap;
  }

  & > *:first-child .input-label-content {
    width: 100%;
  }

  & > *:first-child .checkbox-container {
    margin-top: 0;
    margin-right: var(--medium-dimension);
  }
}

.lawyer-information-form
  > .form-row:nth-child(3)
  > .form-column:last-child
  > .form-row {
  align-items: stretch;

  & > *:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & textarea {
    flex: 1;
  }
}

.lawyer-address-form-heading {
  @extend %block-element-margins;
  display: flex;
}

.lawyer-address-form-heading h2 {
  margin-bottom: 0;
  margin-right: var(--medium-dimension);
}

.lawyer-address-form-heading .checkbox-container {
  margin-top: 0;
}

.lawyer-address-form > .form-row {
  & > *:first-child {
    flex: 6;
  }

  & > *:nth-child(2) {
    flex: 3;
  }

  & > *:nth-child(3) {
    flex: 6;
  }

  & > *:nth-child(4) {
    flex: 3;
  }

  & > *:nth-child(5) {
    flex: 3;
  }
}

.lawyer-billing-form > .form-row {
  & > *:first-child {
    flex: 2;
  }

  & > *:nth-child(2) {
    flex: 2;
  }

  & > *:nth-child(3) {
    flex: 2;
  }

  & > *:nth-child(4) {
    flex: 2;
  }
  & > *:nth-child(5) {
    flex: 5;
  }
}

.lawyer-billing-form > .form-row.isStaffLawyer {
  & > *:first-child {
    flex: 2;
  }

  & > *:nth-child(2) {
    flex: 2;
  }

  & > *:nth-child(3) {
    flex: 6;
  }
}
