.prompt-modal .modal-content {
  max-width: 640px;
}

.prompt .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.prompt .button-bar > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.prompt .button-bar .button {
  margin-bottom: 0;
}

.prompt-content {
  margin-bottom: var(--medium-dimension);
}
