:root {
  --lawyer-summary-verticl-padding: var(--medium-dimension);
}

.lawyer-feedback-metadata {
  display: flex;
  align-items: center;
  padding-top: var(--lawyer-summary-verticl-padding);
  padding-bottom: var(--lawyer-summary-verticl-padding);
  background-color: var(--primary-background-color);
}

.lawyer-feedback-metadata-content {
  flex: 1;
}

.lawyer-feedback-metadata-accessory {
  display: block;
  margin-left: var(--medium-dimension);
  color: var(--list-card-accessory-color);
  text-decoration: inherit;
}

.lawyer-feedback-metadata-row {
  display: flex;
}

.lawyer-feedback-metadata-column {
  flex: 1;
}

.lawyer-feedback-metadata-skeleton .indented-card {
  padding-top: var(--lawyer-summary-verticl-padding);
  padding-bottom: var(--lawyer-summary-verticl-padding);
  border-left-color: var(--light-grey-color);
}

.lawyer-feedback-metadata-skeleton .skeleton-row:first-child {
  margin-bottom: var(--medium-dimension);
}

.lawyer-feedback-metadata-skeleton .skeleton-row:first-child .skeleton-bone {
  width: 440px;
  height: 50px;
}

.lawyer-feedback-metadata-skeleton
  .skeleton-row:not(:first-child)
  .skeleton-column {
  flex: 1;
}
.lawyer-feedback-metadata-skeleton
  .skeleton-row:not(:first-child)
  .skeleton-bone {
  width: 60%;
  height: 23px;
}
