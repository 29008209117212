.header-menu-button {
  @extend reset;
  @extend %button-fonts;
  display: flex;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
  border-width: var(--border-width);
  border-style: solid;
  border-color: transparent;
  align-items: center;
  line-height: 1.2em;
  color: var(--highlight-color);
  cursor: pointer;
  user-select: none;
}

.header-menu-button:focus {
  border-radius: var(--border-radius);
  background-color: var(--soft-blue-color);
}

.header-menu-button .fa-icon {
  font-size: 1.25rem;
  margin-right: var(--narrow-dimension);
}

.header-menu-button.header-menu-button-active,
.header-menu-button.header-menu-button-active:hover,
.header-menu-button.header-menu-button-active:active {
  background-color: var(--highlight-color);
  color: var(--pure-white-color);
  border-color: var(--highlight-color);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 var(--narrow-dimension) var(--shadow-color);
}

.header-menu-button.header-new-button .fa-icon {
  margin-right: 0;
  margin-left: var(--narrow-dimension);
}

.header-menu-button.header-menu-button-active.header-new-button,
.header-menu-button.header-menu-button-active.header-new-button:hover,
.header-menu-button.header-menu-button-active.header-new-button:active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

ul.header-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 240px;
  background-color: var(--pure-white-color);
  border-width: var(--border-width);
  border-style: solid;
  border-color: var(--highlight-color);
  border-radius: var(--border-radius);
  border-top-right-radius: 0;
  box-shadow: 0 0 var(--narrow-dimension) var(--shadow-color);
}

ul.header-menu.header-new-menu {
  min-width: auto;
  border-top-left-radius: 0;
  border-top-right-radius: var(--border-radius);
}

ul.header-menu > li {
  color: var(--primary-content-color);
  font-weight: var(--bold-font-weight);
}

ul.header-menu > li:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--soft-blue-color);
}

a.header-menu-link,
li.header-menu-link {
  display: flex;
  align-items: center;
  color: var(--primary-content-color);
  text-decoration: none;
  padding: var(--medium-dimension);
}

ul.header-menu > li:focus > a.header-menu-link,
a.header-menu-link:hover,
ul.header-menu > li:focus > li.header-menu-link,
li.header-menu-link:hover,
ul.header-menu li.header-menu-link:focus {
  background-color: var(--soft-blue-color);
  color: var(--highlight-color);
}

a.header-menu-link.header-menu-link-active {
  background-color: var(--highlight-color);
  color: var(--pure-white-color);
  cursor: default;
}

a.header-menu-link .fa-icon {
  margin-right: var(--medium-dimension);
  color: var(--highlight-color);
  font-size: 1.5rem;
}

a.header-menu-link.header-menu-link-active .fa-icon {
  color: var(--pure-white-color);
}
