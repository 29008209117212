@import './properties';
@import './placeholders';

body {
  @extend %body-fonts;
}

h1 {
  @extend %heading-fonts;
  font-size: var(--h1-font-size);
}

h2 {
  @extend %heading-fonts;
  font-size: var(--h2-font-size);
}

h3,
h4,
h5,
h6 {
  @extend %heading-fonts;
}
