.label-value-pair span {
  display: inline-block;
}

.label-value-pair span:first-child {
  margin-right: 0.3em;
  font-weight: var(--semi-bold-font-weight);
}

.label-value-pair .fa-icon {
  font-size: 1em;
}
