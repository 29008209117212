.styled-select {
  position: relative;
}

.styled-select select {
  @extend reset;
  @extend %input-colors;
  @extend %input-borders;
  @extend %input-fonts;
  @extend %input-padding;
  padding-right: var(--select-icon-container-width);
  color: var(--input-content-color);
  height: var(--input-calculated-height);
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.styled-select select:focus {
  border-color: var(--highlight-color);
  background-color: var(--soft-blue-color);
}

.styled-select select:disabled {
  @extend %input-disabled;
}

.styled-select .styled-select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--select-icon-container-width);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: var(--input-content-color);
}

.styled-select select:disabled + .styled-select-icon {
  color: var(--input-disabled-color);
}
