:root {
  --law-firm-summary-heading-font-size: 1.5rem;
  --law-firm-summary-heading-line-height: 2.25rem;
}

.law-firm-summary {
  display: flex;
  align-items: center;
  padding: var(--medium-dimension);
}

.law-firm-summary-content {
  flex: 1;
}

.law-firm-summary-accessory {
  display: block;
  margin-left: var(--medium-dimension);
  color: var(--list-card-accessory-color);
  text-decoration: inherit;
}

.law-firm-summary-staff-id {
  margin-bottom: 0;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.law-firm-summary h1 {
  text-transform: capitalize;
  font-size: var(--law-firm-summary-heading-font-size);
  line-height: var(--law-firm-summary-heading-line-height);
  margin-bottom: var(--narrow-dimension);
}
.law-firm-summary p {
  margin-bottom: 0;
}

.law-firm-summary.law-firm-summary-no-content h1 {
  margin-bottom: 0;
}

.law-firm-summary-heading {
  display: block;
  color: inherit;
  text-decoration: inherit;
}

.law-firm-summary-body {
  display: flex;
  align-items: flex-start;
}
.law-firm-summary-body > div:first-of-type {
  width: 50%;
}

.law-firm-summary-body > div:last-of-type {
  display: flex;
  position: relative;
  bottom: calc(1.5rem + var(--narrow-dimension));
}

.law-firm-summary-container-link {
  text-decoration: none;
  color: inherit;
}

.law-firm-summary-body > div:last-of-type .label-value-pair {
  margin-right: var(--medium-dimension);
}

.law-firm-summary-column {
  display: flex;
  align-items: center;
}

.law-firm-summary-column:first-child {
  margin-right: calc(var(--wide-dimension) * 2);
}

.law-firm-summary-column:not(:first-child):not(:last-child) {
  margin-right: var(--wide-dimension);
}

.law-firm-summary-column .label-value-pair span:first-child {
  margin-right: var(--narrow-dimension);
}

.law-firm-summary-editor {
  width: 50%;
}

.law-firm-summary-editor input {
  width: 100%;
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0;
  font-size: var(--law-firm-summary-heading-font-size);
  line-height: var(--law-firm-summary-heading-line-height);
  height: var(--law-firm-summary-heading-line-height);
}

.law-firm-summary-editor input::placeholder {
  font-size: var(--law-firm-summary-heading-font-size);
  line-height: var(--law-firm-summary-heading-line-height);
}

.law-firm-summary-edit-controls {
  display: flex;
  align-items: center;
}

.law-firm-summary-edit-controls > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

/* Skeleton */

.law-firm-summary-skeleton {
  background-color: var(--pure-white-color);
}

.law-firm-summary-skeleton .card.indented-card {
  border-left-color: var(--light-grey-color);
}

.law-firm-summary-skeleton
  .skeleton-row:first-child
  .skeleton-column:first-child {
  flex: 1;
}

.law-firm-summary-skeleton
  .skeleton-row:first-child
  .skeleton-column:last-child {
  flex: 2;
}

.law-firm-summary-skeleton .skeleton-row:first-child .skeleton-bone {
  height: 40px;
  margin-bottom: var(--medium-dimension);
}

.law-firm-summary-skeleton
  .skeleton-row:last-child
  .skeleton-column:not(:last-child) {
  flex: 1;
}

.law-firm-summary-skeleton
  .skeleton-row:last-child
  .skeleton-column:last-child {
  flex: 2;
}

.law-firm-summary-skeleton .skeleton-row:last-child .skeleton-bone {
  height: 20px;
}
