@import './properties';

.banner {
  border-width: 0;
  border-radius: 0;
}

.banner .banner-content {
  font-weight: var(--bold-font-weight);
}

.banner.banner-info {
  background-color: var(--banner-info-background-color);
  border-color: var(--banner-info-border-color);
}

.banner.banner-info .banner-content,
.banner.banner-info .banner-dismiss-button {
  color: var(--banner-info-content-color);
}

.banner.banner-success {
  background-color: var(--banner-success-background-color);
  border-color: var(--banner-success-border-color);
}

.banner.banner-success .banner-progress-value {
  background-color: var(--banner-success-progress-color);
}

.banner.banner-success .banner-content,
.banner.banner-success .banner-dismiss-button {
  color: var(--banner-success-content-color);
}

.banner.banner-warning {
  background-color: var(--banner-warning-background-color);
  border-color: var(--banner-warning-border-color);
}

.banner.banner-warning .banner-content,
.banner.banner-warning .banner-dismiss-button {
  color: var(--banner-warning-content-color);
}

.banner.banner-error {
  background-color: var(--banner-error-background-color);
  border-color: var(--banner-error-border-color);
}

.banner.banner-error .banner-content,
.banner.banner-error .banner-dismiss-button {
  color: var(--banner-error-content-color);
}
