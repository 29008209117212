.form-row {
  flex: 1;
  display: flex;
}

.form-row:not(:last-child) {
  margin-bottom: var(--wide-dimension);
}

.form-row > *:not(.form-column):not(:last-child) {
  margin-right: var(--wide-dimension);
}

.form-row > .form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-row > .form-column:not(:first-child) {
  margin-left: calc(var(--form-gutter) / 2);
}

.form-row > .form-column:not(:last-child) {
  margin-right: calc(var(--form-gutter) / 2);
}
