.input-label .input-label-content {
  @extend %input-label-content;
}

.input-label.input-label-disabled .input-label-content {
  color: var(--input-disabled-color);
}

.input-label input[type='text'],
.input-label input[type='number'],
.input-label input[type='password'],
.input-label input[type='email'],
.input-label input[type='tel'],
.input-label textarea,
.input-label select {
  width: 100%;
}
