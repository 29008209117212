.modal.auto-void-modal .modal-content {
  width: 450px;
}

.modal.auto-void-modal .modal-content > *:last-child {
  margin-bottom: 0;
}

.modal.auto-void-modal .modal-content .activity-indicator-card {
  padding: var(--medium-dimension);
}

.modal.auto-void-modal .modal-content .activity-indicator-card h2 {
  text-align: center;
}

.modal.auto-void-modal .modal-content .button-input-container label {
  display: block;
  margin-bottom: var(--narrow-dimension);
}

.modal.auto-void-modal .modal-content input[type='text'].button-input {
  min-width: 0;
  display: flex;
}

.modal.auto-void-modal .modal-content button.button-input-confirm {
  min-width: 80px;
}
