.provinces-list-heading {
  @extend %block-element-margins;
  display: flex;
}

.provinces-list-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.provinces-list-heading-left h1 {
  margin-bottom: 0;
}

.provinces-list-card h2 {
  display: flex;
  align-items: center;
}

.provinces-list-card h2 > span:first-child {
  min-width: 3rem;
}

.provinces-list-card h2 > .tag {
  margin-left: var(--medium-dimension);
}

@media print {
  .provinces-list-card-print {
    padding: var(--medium-dimension);
    background-color: var(--x-light-grey-color);
    -webkit-print-color-adjust: exact;
    break-inside: avoid;
  }

  .provinces-list li:nth-child(2n + 2) .provinces-list-card-print {
    background-color: var(--pure-white-color);
  }

  .provinces-list-card-print h3 {
    display: flex;
    align-items: center;
    font-weight: var(--bold-font-weight);
  }

  .provinces-list-card-print h3 > .tag {
    margin-left: var(--medium-dimension);
  }

  .provinces-list-card-print ul {
    padding-left: 2rem;
    margin-bottom: 0;
  }

  .provinces-list-card-print ul > li > span {
    display: inline-block;
  }

  .provinces-list-card-print h3 > span:first-child,
  .provinces-list-card-print ul > li > span:first-child {
    min-width: 3rem;
  }
}
