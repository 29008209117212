.cheque-run-error .error-description {
  color: var(--error-color);
}

.cheque-run-error .clipboard-button {
  margin-bottom: var(--medium-dimension);
}

.cheque-run-error .button-bar {
  display: flex;
}

.cheque-run-error .button-bar > * {
  flex: 1;
}
