:root {
  --fee-schedule-card-heading-content-color: var(--pure-white-color);
}

.fee-schedule-card {
  @extend %block-element-margins;
  background-color: var(--dark-blue-grey-color);
  border-radius: 5px;
  overflow: hidden;
}

.fee-schedule-card-heading {
  display: flex;
  align-items: center;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
}

.fee-schedule-card-heading .form-row {
  margin: 0;
}

.fee-schedule-card-heading .form-row:first-child {
  & > *:first-child {
    flex: 1;
  }
  & > *:nth-child(2) {
    flex: 3;
  }
}

.fee-schedule-card-heading h2 {
  margin-bottom: 0;
  margin-right: var(--medium-dimension);
  color: var(--fee-schedule-card-heading-content-color);
}

.fee-schedule-card-heading > .input-help {
  flex: 1;
}

.fee-schedule-card-heading .name-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.fee-schedule-card-heading input[type='text'],
.fee-schedule-card-heading
  input[type='text']:focus:not(:read-only):not(:disabled),
.fee-schedule-card-heading input[type='text']::placeholder {
  @extend reset;
  @extend %heading-fonts;
  font-size: var(--h2-font-size);
  color: var(--fee-schedule-card-heading-content-color);
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--fee-schedule-card-heading-content-color);
  width: 75%;
}

.fee-schedule-card-heading input[type='text']::placeholder {
  opacity: 0.7;
}

.fee-schedule-card-heading .active-lawyer-type-radio-container {
  margin-top: 0;
}

.fee-schedule-card-heading .tag.tag-error {
  color: var(--soft-red-color);
  background-color: var(--muted-error-color);
}

.fee-schedule-card-actions {
  display: flex;
  margin-left: var(--medium-dimension);
}

.fee-schedule-card-actions > button.button-link-appearance {
  color: var(--fee-schedule-card-heading-content-color);
}

.fee-schedule-card-actions > button.button-link-appearance:hover:not(:disabled),
.fee-schedule-card-actions > button.button-link-appearance:focus:not(:disabled),
.fee-schedule-card-actions
  > button.button-link-appearance:active:not(:disabled) {
  color: var(--fee-schedule-card-heading-content-color);
  opacity: 0.7;
}

.fee-schedule-card-actions > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.fee-schedule-card-content {
  height: 0;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  background-color: var(--secondary-background-color);
}

.fee-schedule-card-content .release-containers {
  padding: var(--medium-dimension);
  display: flex;
  justify-content: space-between;
}

.fee-schedule-card-content .release-containers:nth-child(2n + 2) {
  background-color: var(--soft-yellow-color);
}

.fee-schedule-card-content .release-containers > div {
  display: flex;
  color: var(--black-color);
}

.fee-schedule-card-content .release-containers p,
.fee-schedule-card-content a {
  margin: 0;
  margin-right: var(--narrow-dimension);
  text-decoration: none;
}

.fee-schedule-card-content .release-containers svg {
  color: var(--black-color);
}

.fee-schedule-card-required-fields {
  display: flex;
}

.fee-schedule-card .release-actions {
  display: flex;
}

.fee-schedule-card .release-actions .button-link-appearance {
  color: var(--fee-schedule-card-heading-content-color);
  margin-right: var(--medium-dimension);
  text-decoration: underline;
  font-weight: inherit;
}

.fee-schedule-card
  .release-actions
  .button-link-appearance:hover:not(:disabled) {
  color: var(--fee-schedule-card-heading-content-color);
  opacity: 0.7;
}

.fee-schedule-card p.bold {
  font-weight: var(--bold-font-weight);
}

.fee-schedule-card .release-actions span.button-link-appearance,
.fee-schedule-card
  .release-actions
  span.button-link-appearance:hover:not(:disabled),
.fee-schedule-card
  .release-actions
  button.button-link-appearance:not(:disabled):focus {
  color: var(--fee-schedule-card-heading-content-color);
  opacity: 0.3;
}

.archive-modal,
.delete-modal {
  max-width: 750px;
}

.archive-modal .button-bar,
.delete-modal .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.archive-modal .button-bar button,
.delete-modal .button-bar button {
  margin-bottom: 0;
  margin-left: var(--narrow-dimension);
}
