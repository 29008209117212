:root {
  --pagination-button-border-width: var(--border-width);
}

ul.pagination {
  @extend %block-element-margins;
  display: flex;
  list-style: none;
  padding: 0;
}

li.pagination-spacer {
  @extend %default-button-colors;
  background-color: transparent;
  padding: var(--medium-dimension);
}

button.pagination-button {
  @extend reset;
  @extend %default-button-colors;
  background-color: transparent;
  padding: calc(
    var(--medium-dimension) - var(--pagination-button-border-width)
  );
  border: var(--pagination-button-border-width) solid transparent;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 1.25rem;
  font-weight: var(--bold-font-weight);
  line-height: 1.25rem;
  cursor: pointer;
}

button.pagination-button:focus,
button.pagination-button:hover,
button.pagination-button:active {
  @extend %hover-button-colors;
  background-color: transparent;
  border-color: transparent;
}

button.pagination-number-button:focus,
button.pagination-number-button:hover,
button.pagination-number-button:active {
  text-decoration: underline;
}

button.pagination-number-button.pagination-current-page {
  @extend %highlight-button-colors;
  text-decoration: none;
}

button.pagination-button.pagination-previous-button:focus,
button.pagination-button.pagination-next-button:focus {
  color: var(--highlight-color);
  border-color: var(--highlight-color);
  background-color: var(--soft-blue-color);
}

button.pagination-number-button.pagination-current-page:focus,
button.pagination-number-button.pagination-current-page:hover,
button.pagination-number-button.pagination-current-page:active {
  @extend %highlight-hover-button-colors;
}

button.pagination-button:disabled {
  @extend %disabled-button-styles;
}
