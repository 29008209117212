button.button-link-appearance {
  @extend reset;
  @extend %button-fonts;
  display: flex;
  align-items: center;
  line-height: 1.2em;
  color: var(--highlight-color);
  cursor: pointer;
}

button.button-link-appearance > .fa-icon {
  font-size: 1em;
  margin-right: var(--narrow-dimension);
}

button.button-link-appearance:not(:disabled):focus,
button.button-link-appearance:not(:disabled):hover,
button.button-link-appearance:not(:disabled):active {
  color: var(--dark-blue-grey-color);
  text-decoration: underline;
}

button.button-link-appearance:disabled {
  @extend %disabled-button-styles;
}
