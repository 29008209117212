.print-manager-content {
  display: none;
}

.print-manager-content [data-screen-only='true'],
.print-manager-children [data-print-only='true'] {
  display: none !important;
}

@media print {
  .print-manager-content {
    display: block;
  }

  .print-manager-content:not(:empty) ~ .print-manager-children {
    display: none;
  }
}
