ul.release-print-metadata {
  list-style: none;
  padding: 0;

  & > li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: var(--wide-dimension);
    }
  }
}

ol.release-print-legal-problems {
  list-style: none;
  padding: 0;

  & > li {
    padding: var(--medium-dimension);
    margin-bottom: var(--medium-dimension);
    break-inside: avoid;
    border: 1px solid var(--medium-grey-color);
    font-size: 0.75rem;

    & > table {
      margin-bottom: 0;

      & td > span {
        display: flex;
        align-items: center;

        & > .fa-icon {
          font-size: 0.75rem;
          margin-left: var(--narrow-dimension);
        }
      }
    }
  }
}

ul.release-print-legal-problem-metadata {
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: var(--medium-dimension);
  font-weight: var(--bold-font-weight);

  & > li {
    margin-right: var(--wide-dimension);

    &:first-child {
      flex: 3;
    }

    &:last-child {
      flex: 7;
    }

    & > span:first-child {
      margin-right: var(--narrow-dimension);
    }
  }
}

.release-print-legal-problem-details {
  display: flex;

  & > ul {
    flex: 4;
    list-style: none;
    padding: 0;

    &:first-child,
    &:last-child {
      flex: 3;
    }

    &:not(:last-child) {
      margin-right: var(--wide-dimension);
    }

    & > li {
      display: flex;
      justify-content: space-between;

      & > span:first-child {
        margin-right: var(--medium-dimension);
      }
    }
  }
}

.release-print-layout table {
  border-radius: 0;

  & thead th {
    padding-top: var(--narrow-dimension);
    padding-bottom: var(--narrow-dimension);
  }
}
