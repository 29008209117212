header.search-header .search-input {
  margin-right: var(--medium-dimension);
}

header.search-header button.search-header-new {
  display: flex;
  align-items: center;
}

header.search-header button.search-header-new .fa-icon {
  margin-left: var(--narrow-dimension);
}
