.feedback-reports-page-heading {
  margin-top: 0;
  margin-bottom: var(--wide-dimension);
  display: flex;
}

.feedback-reports-page-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.feedback-reports-page-heading-left h1 {
  margin-bottom: 0;
}

.feedback-report-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: var(--medium-dimension);
}

.feedback-report-item:nth-child(2n + 2) {
  background-color: var(--soft-yellow-color);
}

.feedback-report-item h2 {
  margin-bottom: 0;
}

.run-feedback-report-modal .form-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--wide-dimension);
}

.run-feedback-report-modal .form-container .date-picker-input {
  width: 150px;
}
