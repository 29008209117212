.print-header {
  @extend %block-element-margins;
  display: flex;
  align-items: center;
}

.print-header-logo {
  height: 60px;
  margin-right: var(--medium-dimension);
}

.print-header-content {
  flex: 1;
}

.print-header-content .print-header-title {
  color: var(--medium-grey-color);
}

.print-header-content h1 {
  margin: 0;
  color: var(--primary-content-color);
}

.print-header-date {
  color: var(--medium-grey-color);
  margin-left: var(--medium-dimension);
}
