.page.lawyer-feedback .lawyer-feedback-actions {
  @extend %block-element-margins;
  display: flex;
  justify-content: space-between;
}

.page.lawyer-feedback .lawyer-feedback-actions .feedback-sort-menu {
  max-width: 260px;
  margin-bottom: 0;
}

.add-lawyer-feedback-modal .modal-content {
  width: 708px;
}

.add-lawyer-feedback .button-bar,
.delete-lawyer-feedback .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-lawyer-feedback .button-bar .button,
.delete-lawyer-feedback .button-bar .button {
  margin: 0;
}

.add-lawyer-feedback .button-bar > *:not(:last-child),
.delete-lawyer-feedback .button-bar > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.lawyer-feedback-form {
  @extend %block-element-margins;
}

.lawyer-feedback-form > .form-row:first-child > .input-help {
  width: 200px;
}

.lawyer-feedback-form > .form-row:first-child > button {
  width: 100px;
  height: 60px;
}

.lawyer-feedback-form > .form-row:not(:first-child) > * {
  flex: 1;
}

.lawyer-feedback-attachments-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--medium-dimension);
}

.lawyer-feedback-attachments-heading h2 {
  margin-bottom: 0;
}

.lawyer-feedback-attachments-input {
  margin-left: var(--medium-dimension);
}

.lawyer-feedback-attachments-input input {
  display: none;
}

.lawyer-feedback-attachments-list li {
  @extend %feedback-document;
  width: 100%;
  margin-bottom: var(--medium-dimension);
}

.lawyer-feedback-attachments-list li.existing-file {
  background-color: var(--light-blue-grey-color);
  color: var(--medium-grey-color);
}

.lawyer-feedback-attachments-list li button {
  @extend reset;
  cursor: pointer;
}

.lawyer-feedback-attachments-list li.lawyer-feedback-attachment-invalid {
  border-color: var(--error-color);
  background-color: var(--error-color);
  color: var(--pure-white-color);
}

.lawyer-feedback-attachments-list li.lawyer-feedback-attachment-invalid button {
  color: var(--pure-white-color);
}

.lawyer-feedback-attachments-error {
  font-size: var(--input-help-font-size);
  font-weight: var(--bold-font-weight);
  line-height: var(--input-help-line-height);
  color: var(--input-help-invalid-color);
}
