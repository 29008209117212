.page.case-page ul.tabs > li.tabs-item {
  text-align: center;
}

.page.case-page ul.tabs > li.tabs-item > a {
  width: 100%;
}

.case-page-heading {
  @extend %block-element-margins;
  display: flex;
}

.case-page-heading-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.case-page-heading-left {
  flex: 1;
}

.case-page-heading-left p {
  margin-bottom: 0;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.case-page-metadata {
  display: flex;
  align-items: center;
}

.case-page-metadata h1 {
  margin-right: var(--medium-dimension);
  margin-bottom: 0;
}

.case-page-metadata .tag {
  font-size: 1.2rem;
  margin-right: var(--medium-dimension);
}
.case-page-metadata .label-value-pair {
  font-size: 1.2rem;
}

.case-page-metadata .tag.active {
  background-color: var(--pale-green-color);
  color: var(--green-color);
  border: 1px solid var(--green-color);
}

.case-page-metadata .tag.closed {
  background-color: var(--soft-blue-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
}

.case-page-metadata .tag.denied,
.case-page-metadata .tag.void {
  background-color: var(--soft-red-color);
  color: var(--red-color);
  border: 1px solid var(--red-color);
}
