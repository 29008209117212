.clipboard-status-icon {
  width: 1em;
}

.clipboard-status-icon-success {
  color: var(--success-color);
}

.clipboard-status-icon-error {
  color: var(--error-color);
}
