.case-lawyer-information-form > .form-row {
  & > *:first-child {
    flex: 5;
  }

  & > *:nth-child(2) {
    flex: 5;
  }

  & > *:nth-child(3) {
    flex: 6;
  }

  & > *:nth-child(4) {
    flex: 4;
  }
  & > *:nth-child(5) {
    flex: 4;
  }
}

.case-lawyer-information-form > .form-row:last-of-type > * {
  flex: 1;
}
