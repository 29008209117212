ul,
ol {
  @extend %block-element-margins;
  padding: 0;
  list-style-position: inside;
}

ul.unstyled-list,
ol.unstyled-list {
  list-style: none;
}
