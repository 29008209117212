.client-summary {
  display: flex;
  align-items: center;
}

.client-summary-content {
  flex: 1;
}

.client-summary-accessory {
  display: block;
  margin-left: var(--medium-dimension);
  color: var(--list-card-accessory-color);
  text-decoration: inherit;
}

.client-summary-heading {
  display: flex;
  justify-content: space-between;
}
.client-summary-heading a {
  display: block;
  color: inherit;
  text-decoration: inherit;
}

.client-summary-body {
  display: flex;
}

.client-summary-column {
  flex: 3;
}

.client-summary-column:nth-child(2),
.client-summary-column:nth-child(3) {
  flex: 4;
}

.client-summary-column:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.client-summary-column:nth-child(4) .label-value-pair:not(:last-child) {
  margin-bottom: var(--narrow-dimension);
}

.client-summary-column .label-value-pair span:first-child {
  margin-right: var(--narrow-dimension);
}

/* Skeleton */

.client-summary-skeleton {
  background-color: var(--pure-white-color);
}

.client-summary-skeleton .card.indented-card {
  border-left-color: var(--light-grey-color);
}

.client-summary-skeleton
  .skeleton-row:first-child
  .skeleton-column:first-child {
  flex: 1;
}

.client-summary-skeleton .skeleton-row:first-child .skeleton-column:last-child {
  flex: 2;
}

.client-summary-skeleton .skeleton-row:first-child .skeleton-bone {
  height: 40px;
  margin-bottom: var(--medium-dimension);
}

.client-summary-skeleton
  .skeleton-row:last-child
  .skeleton-column:not(:last-child) {
  flex: 5;
}

.client-summary-skeleton .skeleton-row:last-child .skeleton-column:last-child {
  flex: 4;
}
