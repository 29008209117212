.fab-position {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: 1000;
}

.fab-position-content {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: var(--layout-content-width);
  margin: 0 auto;
  margin-bottom: var(--fab-bottom-offset);
  padding: 0 var(--wide-dimension);
  pointer-events: none;
}
