.lawyer-cif-form {
  display: flex;

  & > h2 {
    margin-right: var(--form-gutter);
    margin-bottom: 0;
  }

  & select {
    min-width: 9rem;
  }
}
