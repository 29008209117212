.legal-problem-code-detail-heading {
  @extend %block-element-margins;
  display: flex;
}

.legal-problem-code-detail-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.legal-problem-code-detail-heading-left h1 {
  margin-bottom: 0;
}

.legal-problem-code-detail .deactivate-toggle-container {
  margin-left: var(--medium-dimension);
}
