.select-lawyer-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-lawyer-button-container .action-card {
  flex: 1;
  height: 240px;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
}

.select-lawyer-button-container .action-card .action-card-header {
  display: none;
}

.select-lawyer-button-container .action-card .action-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.select-lawyer-button-container .action-card .action-card-content svg {
  height: 90px;
  width: 90px;
  color: var(--blue-color);
  opacity: 0.5;
}

.select-lawyer-button-container .action-card .action-card-content h2 {
  font-size: 1.25rem;
  margin: 0;
  margin-top: 1rem;
  text-align: center; /* preserve centering if the content wraps */
}

.search-lawyer-modal {
  min-width: 840px;
  margin: var(--medium-dimension) 0;
}

.search-lawyer-modal .list-button-card button {
  padding: var(--narrow-dimension) var(--medium-dimension);
}

.search-lawyer-modal .list-button-card h2 {
  font-size: 1.25rem;
  font-weight: normal;
}

.search-lawyer-modal .pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-lawyer-modal {
  min-width: 840px;
  margin: var(--medium-dimension) 0;
}

.search-lawyer-modal .list-button-card button {
  padding: var(--narrow-dimension) var(--medium-dimension);
}

.search-lawyer-modal .list-button-card h2 {
  font-size: 1.25rem;
  font-weight: normal;
}

.search-lawyer-modal .pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-lawyer-modal .general-search-input {
  margin-bottom: 0;
}

.select-lawyer-modal .go-button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

.select-lawyer-modal .layout-container.search-container {
  padding-bottom: var(--medium-dimension);
}

.select-lawyer-modal .hidden {
  display: none;
}

.select-lawyer-modal .search-divider p {
  margin: 0 var(--narrow-dimension);
}
.select-lawyer-modal .line-divider {
  border-bottom: 1px solid var(--black-color);
  flex: 1;
  margin-bottom: var(--narrow-dimension);
}

.select-lawyer-modal .lawyer-search-second-step .styled-select select {
  background-color: var(--soft-blue-color);
  height: inherit;
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--medium-dimension);
}

.staff-lawyer-search-bar .layout-column:first-child {
  flex: 3;
}
.staff-lawyer-search-bar .layout-column:nth-child(2) {
  flex: 2;
}

.staff-lawyer-search-bar .layout-column:nth-child(2) .styled-select select {
  background-color: var(--soft-blue-color);
  height: inherit;
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--medium-dimension);
}
