@import './properties';

.toggle {
  display: flex;
  max-width: 320px;
}

.toggle label {
  @extend %button-fonts;
  flex: 1;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
  border-width: var(--border-width);
  border-style: solid;
  border-color: var(--x-light-grey-color);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.toggle label:first-child {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle label:last-child {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.toggle label.toggle-label-on {
  background-color: var(--toggle-on-background-color);
  color: var(--toggle-on-color);
}

.toggle label.toggle-label-on.toggle-label-active {
  background-color: var(--toggle-on-active-background-color);
  border-color: var(--toggle-on-active-background-color);
  color: var(--toggle-on-active-color);
}

.toggle label.toggle-label-off {
  background-color: var(--toggle-off-background-color);
  color: var(--toggle-off-color);
}

.toggle label.toggle-label-off.toggle-label-active {
  background-color: var(--toggle-off-active-background-color);
  border-color: var(--toggle-off-active-background-color);
  color: var(--toggle-off-active-color);
}

.toggle.toggle-disabled label {
  background-color: var(--toggle-disabled-background-color);
  border-color: var(--toggle-disabled-background-color);
  color: var(--toggle-disabled-color);
  cursor: default;
}

.toggle.toggle-disabled label.toggle-label-active {
  background-color: var(--toggle-disabled-active-background-color);
  border-color: var(--toggle-disabled-active-background-color);
  color: var(--toggle-disabled-active-color);
}

.toggle input[type='radio'] {
  display: none;
}

.toggle:focus {
  outline: none;
}

.toggle:focus label:not(.toggle-label-active) {
  text-decoration: underline;
  outline: none;
}
