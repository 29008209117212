.case-billing-form > .form-row:first-child > .form-column:first-child {
  flex: 12;
}

.case-billing-form
  > .form-row:first-child
  > .form-column:first-child
  > .form-row:first-child {
  & > *:first-child {
    flex: 4;
  }

  & > *:nth-child(2) {
    flex: 7;
  }

  & > *:nth-child(3) {
    flex: 1;
  }
}

.case-billing-form
  > .form-row:first-child
  > .form-column:first-child
  > .form-row:nth-child(2) {
  & > *:first-child {
    flex: 4;
  }

  & > *:nth-child(2) {
    flex: 7;
  }

  & > *:nth-child(3) {
    flex: 1;
  }
}

.case-billing-form
  > .form-row:first-child
  > .form-column:first-child
  > .form-row:last-of-type {
  & > *:first-child {
    flex: 1;
  }
}

.payee-form {
  margin-bottom: var(--medium-dimension);
}

.payee-form > .form-row:first-child {
  & > *:first-child {
    flex: 1;
  }
}

.payee-form > .form-row:nth-last-child(2) {
  & > *:first-child {
    flex: 2;
  }
  & > *:nth-child(2) {
    flex: 1;
  }
}

.payee-form > .form-row:last-of-type {
  & > * {
    flex: 1;
  }
}

.case-billing-form > .form-row:first-child > .form-column:nth-child(2) {
  flex: 7;
}

.case-billing-form > .form-row:first-child > .form-column:last-child {
  flex: 5;
}

.case-billing-form > .form-row:first-child > .form-column:not(:first-child) {
  & h3 {
    margin-bottom: var(--form-gutter);
    text-align: right;
  }

  & .input-help {
    margin-bottom: 2px;
  }

  & .input-help:nth-child(2) {
    margin-bottom: var(--narrow-dimension);
  }

  & .input-help-content {
    text-align: right;
  }

  & .input-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .input-label-content {
    margin-right: var(--form-gutter);
  }

  & input {
    max-width: 100px;
  }
}

.case-billing-form > .form-row:first-child > .form-column:nth-child(2) {
  & .input-help:last-child .input-label-content {
    font-weight: var(--bold-font-weight);
  }
}
