:root {
  --lawyer-type-card-heading-content-color: var(--pure-white-color);
}

.lawyer-type-card {
  background-color: var(--dark-blue-grey-color);
  border-radius: 5px;
}

.lawyer-type-card-heading {
  display: flex;
  align-items: center;
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
}

.lawyer-type-card-heading h2 {
  margin-bottom: 0;
  margin-right: var(--medium-dimension);
  color: var(--lawyer-type-card-heading-content-color);
}

.lawyer-type-card-heading h2:not(:first-of-type),
.lawyer-type-card-heading > .input-help {
  flex: 1;
}

.lawyer-type-card-heading input[type='text'],
.lawyer-type-card-heading
  input[type='text']:focus:not(:read-only):not(:disabled),
.lawyer-type-card-heading input[type='text']::placeholder {
  @extend reset;
  @extend %heading-fonts;
  font-size: var(--h2-font-size);
  color: var(--lawyer-type-card-heading-content-color);
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--lawyer-type-card-heading-content-color);
  width: 75%;
}

.lawyer-type-card-heading input[type='text']::placeholder {
  opacity: 0.7;
}

.lawyer-type-card-actions {
  display: flex;
  margin-left: var(--medium-dimension);
}

.lawyer-type-card-actions > button.button-link-appearance {
  color: var(--lawyer-type-card-heading-content-color);
}

.lawyer-type-card-actions > button.button-link-appearance:hover,
.lawyer-type-card-actions > button.button-link-appearance:focus,
.lawyer-type-card-actions > button.button-link-appearance:active {
  color: var(--lawyer-type-card-heading-content-color);
  opacity: 0.7;
}

.lawyer-type-card-actions > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.lawyer-type-card-content {
  background-color: var(--secondary-background-color);
}

.lawyer-type-card-content form {
  padding: var(--medium-dimension);
}

.lawyer-type-card-content form > .form-row:first-child > * {
  flex: 1;
}

.lawyer-type-card-required-fields {
  display: flex;
}

.lawyer-type-card-required-fields .checkbox-container {
  margin-top: 0;
}

.lawyer-type-card-required-fields .checkbox-container:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.lawyer-type-card .deactivate-toggle-container {
  margin-top: 1.25rem;
}
