.button-bar > button.button {
  display: inline-block;
  margin-bottom: var(--medium-dimension);

  &:not(:last-child) {
    margin-right: var(--medium-dimension);
  }
}

.button-stack {
  display: flex;
  flex-direction: column;

  & > button.button {
    display: block;
    margin-bottom: var(--medium-dimension);
  }
}
