@keyframes skeleton-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

%skeleton-opacity-animation {
  animation-name: skeleton-opacity;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.skeleton.skeleton-animated .skeleton-content {
  @extend %skeleton-opacity-animation;
}

.skeleton-row {
  display: flex;
}

.skeleton-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.skeleton-column:not(:last-child) {
  margin-right: var(--wide-dimension);
}

.skeleton-bone {
  width: 100%;
  height: var(--narrow-dimension);
  background-color: var(--medium-grey-color);
}

.skeleton-break {
  width: 100%;
  height: var(--narrow-dimension);
}

.skeleton-row:not(:last-child),
.skeleton-bone:not(:last-child),
.skeleton-break:not(:last-child) {
  margin-bottom: var(--narrow-dimension);
}
