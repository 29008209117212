.page.case-log .case-log-actions {
  display: flex;
  justify-content: end;
  margin-bottom: var(--medium-dimension);
}

.page.case-log .case-log-actions .client-log-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--medium-dimension);
}

.page.case-log .case-log-actions .client-log-link .fa-icon {
  margin-left: var(--medium-dimension);
}
