/* Please read the print-layouts-README file before editing the print layouts. */

:root {
  --ntp-print-tickbox-dimension: 10px;
  --ntp-print-instruction-inset: 20px;
  --ntp-print-instruction-number-width: 20px;
}

.ntp-print-gutter {
  height: 2.19%; /* 20px */
}

.ntp-print-tickbox {
  position: relative;
  width: var(--ntp-print-tickbox-dimension);
  height: var(--ntp-print-tickbox-dimension);
  border: 1px solid var(--light-grey-color);
  border-radius: 3px;
}

.ntp-print-tickbox-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > .fa-icon {
    font-size: 10px;
  }
}

/* Header */

.ntp-print-header {
  height: 19.08%; /* 174px */
  text-align: center;
}

.ntp-print-header > .print-line:first-child {
  height: 9.77%; /* 17px */
  font-size: 12px;
  color: var(--light-grey-color);
}

.ntp-print-header > .print-line:nth-child(2) {
  height: 18.96%; /* 33px */
}

.ntp-print-header > .print-line:nth-child(2) > h1 {
  font-size: 24px;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
  margin: 0;
}

.ntp-print-header-warning {
  height: 34.48%; /* 60px */
}

.ntp-print-header-warning > .print-line:first-child {
  height: 36.66%; /* 22px */
}

.ntp-print-header-warning > .print-line:first-child > h2 {
  font-size: 16px;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
  text-decoration: underline;
  margin: 0;
}

.ntp-print-header-warning > .print-line:not(:first-child) {
  height: 31.66%; /* 19px */
  font-size: 14px;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
}

.ntp-print-header-metadata {
  display: flex;
  justify-content: space-between;
  height: 28.75%; /* 50px */
  font-size: 12px;
}

.ntp-print-header-metadata > ul {
  height: 100%;
  margin: 0;
}

.ntp-print-header-metadata > ul:first-child {
  text-align: left;
}

.ntp-print-header-metadata > ul:last-child {
  text-align: right;
}

.ntp-print-header-metadata > ul > li {
  height: 33%;
}

.ntp-print-header-gutter {
  height: 5.75%; /* 10px */
}

/* Cooperating Lawyer */

.ntp-print-cooperating-lawyer {
  display: flex;
  flex-direction: column;
  height: 38.93%; /* 355px */
  border-top: 1px solid var(--light-grey-color);
}

.ntp-print-cooperating-lawyer-gutter {
  height: 2.81%; /* 10px */

  &:first-child {
    height: 5.63%; /* 20px */
  }
}

.ntp-print-cooperating-lawyer-heading {
  display: flex;
  align-items: center;
}

.ntp-print-cooperating-lawyer-heading > .ntp-print-tickbox {
  margin-right: calc(
    var(--ntp-print-instruction-inset) - var(--ntp-print-tickbox-dimension)
  );
}

.ntp-print-cooperating-lawyer-heading > .print-line {
  height: 5.35%; /* 19px */
}

.ntp-print-cooperating-lawyer-heading > .print-line > h3 {
  font-size: 14px;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
  margin: 0;
}

.ntp-print-cooperating-lawyer-metadata {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: calc(
    var(--ntp-print-instruction-inset) +
      var(--ntp-print-instruction-number-width)
  );
  font-size: 12px;
}

.ntp-print-cooperating-lawyer-metadata-row {
  /* Reference width is 632px */
  display: flex;
  flex: 1;
}

.ntp-print-cooperating-lawyer-metadata-row > *:last-child {
  flex: 2;
}

.ntp-print-cooperating-lawyer-metadata-row > *:not(:first-child) {
  padding-left: var(--narrow-dimension);
}

.ntp-print-cooperating-lawyer-metadata-row > *:not(:last-child) {
  padding-right: var(--narrow-dimension);
}

.ntp-print-cooperating-lawyer-metadata-label {
  height: 100%;

  &:first-child {
    width: 11.39%; /* 72px */
  }

  &:nth-child(3) {
    width: 18.83%; /* 119px */
  }
}

.ntp-print-cooperating-lawyer-metadata-value {
  height: 100%;

  &:nth-child(2) {
    width: 44.3%; /* 280px */
  }

  &:nth-child(4) {
    width: 23.89%; /* 151px */
  }

  & .print-line {
    width: 100%;
    height: 100%;
  }
}

.ntp-print-cooperating-lawyer-metadata-row:last-child {
  flex: 2;

  & .print-line {
    height: 50%;
  }
}

/* Non-cooperating Lawyer */

.ntp-print-non-cooperating-lawyer {
  height: 37.06%; /* 338px */
  border-top: 1px solid var(--light-grey-color);
}

.ntp-print-non-cooperating-lawyer-gutter {
  height: 3.11%; /* 10px */

  &:first-child {
    height: 6.23%; /* 20px */
  }
}

.ntp-print-non-cooperating-lawyer-heading {
  display: flex;
  align-items: center;
}

.ntp-print-non-cooperating-lawyer-heading > .ntp-print-tickbox {
  margin-right: calc(
    var(--ntp-print-instruction-inset) - var(--ntp-print-tickbox-dimension)
  );
}

.ntp-print-non-cooperating-lawyer-heading > .print-line {
  height: 5.92%; /* 19px */
}

.ntp-print-non-cooperating-lawyer-heading > .print-line > h3 {
  font-size: 14px;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
  margin: 0;
}

.ntp-print-non-cooperating-lawyer
  > .ntp-print-instruction-first-non-cooperating {
  height: 56.21%; /* 190px */

  & .ntp-print-instruction-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &
    .ntp-print-instruction-content
    > ul.ntp-print-non-cooperating-lawyer-contact {
    height: 58.96%; /* 102px */
    margin: 0;
    text-align: center;

    & > li {
      height: calc(100% / 6); /* 6 lines */
    }

    & > li:first-child {
      font-weight: var(--bold-font-weight);
    }
  }
}

/* Instructions */

.ntp-print-instruction {
  display: flex;
  padding-left: var(--ntp-print-instruction-inset);
  font-size: 12px;
}

.ntp-print-instruction strong {
  font-weight: var(--bold-font-weight);
}

.ntp-print-instruction-number {
  width: var(--ntp-print-instruction-number-width);
}

.ntp-print-instruction-content {
  flex: 1;
}

.ntp-print-instruction-text p {
  margin: 0;
}
