/*
.note-card:nth-child(2n+2) {
  background-color: var(--primary-background-color);
}
*/

.note-card .note-card-header,
.note-card .note-card-body:not(:last-child) {
  @extend %block-element-margins;
}

.note-card .note-card-body p {
  white-space: pre-line;
}

.note-card .note-card-footer {
  display: flex;
  justify-content: flex-end;
}

.note-card .note-card-footer button:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.note-card ul.note-card-meta {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--black-color);
  font-weight: var(--semi-bold-font-weight);
}

.note-card ul.note-card-meta > li:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.note-cards-list {
  @extend %block-element-margins;
}

.note-cards-list .note-card {
  margin: 0;
}

.note-cards-list .note-card:nth-child(2n + 2) {
  background-color: var(--soft-yellow-color);
}

@media print {
  .note-card {
    break-inside: avoid;
  }

  .note-card .note-card-body:not(:last-child) {
    margin-bottom: 0;
  }

  .note-card {
    background-color: var(--x-light-grey-color);
  }

  .note-cards-list .note-card:nth-child(2n + 2) {
    background-color: var(--pure-white-color);
  }
}
