.search-filter-radio-group {
  @extend %block-element-margins;
}

.search-filter-radio-group label {
  display: flex;
  align-items: center;
}

.search-filter-radio-group label:not(:last-child) {
  margin-bottom: var(--narrow-dimension);
}

.search-filter-radio-group label input {
  margin-right: var(--narrow-dimension);
}

.search-filter-radio-group label span {
  flex: 1;
}

.search-filter-inputs > .input-label {
  display: block;
  margin-bottom: var(--medium-dimension);
}

.search-filter-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--dimension-unit);
}

.search-filter-heading h3 {
  font-size: 1.25rem;
  line-height: 2.5rem;
  margin-bottom: 0;
  color: var(--primary-content-color);
}

.search-filter-inputs .input-label-content {
  font-size: 1.25rem;
  line-height: 2.5rem;
  margin-bottom: var(--dimension-unit);
  color: var(--primary-content-color);
}

.search-filter .action-card-content {
  /* Off-grid value to align the top line of the filter
   * with the top line of the search heading:
   */
  padding-top: 12px;
}
