.page.fee-schedule ul.tabs > li.tabs-item,
.page.release-page ul.tabs > li.tabs-item {
  text-align: center;
}

.page.fee-schedule ul.tabs > li.tabs-item > a,
.page.release-page ul.tabs > li.tabs-item > a {
  width: 100%;
}

.fee-schedule-heading {
  @extend %block-element-margins;
  display: flex;
}

.fee-schedule-heading-left {
  flex: 1;
}

.release-heading a {
  color: var(--blue-color);
}
