header.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--secondary-background-color);
  box-shadow: 0 0 var(--narrow-dimension) var(--shadow-color);
}

header.fixed-header .header-content {
  display: flex;
  align-items: stretch;
  height: var(--layout-header-height);
  margin: 0 auto;
  padding-top: var(--medium-dimension);
  padding-bottom: var(--medium-dimension);
  padding-left: var(--wide-dimension);
  padding-right: var(--wide-dimension);
}

header.fixed-header .header-logo {
  height: 50px;
  margin-right: var(--wide-dimension);
}

header.fixed-header .header-menus {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: var(--wide-dimension);
}
