:root {
  --footer-height: 56px;
}

html {
  position: relative;
  min-height: 100%;
  padding-bottom: var(--footer-height);
}

footer.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--wide-dimension);
  padding-right: var(--wide-dimension);
  background-color: var(--secondary-background-color);
  box-shadow: 0 0 var(--narrow-dimension) var(--shadow-color);
}

footer.footer > *:not(:last-child) {
  margin-right: var(--narrow-dimension);
}

@media print {
  footer.footer {
    display: none;
  }
}
