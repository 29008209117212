.move-report-modal {
  min-width: 840px;
}

.move-report-modal .button-bar {
  display: flex;
  justify-content: flex-end;
}

.move-report-modal .button-bar button {
  margin-bottom: 0;
  height: 3.75rem;
}

.move-report-modal .button-bar button:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.move-report-modal .list-button-card button {
  padding: var(--narrow-dimension) var(--medium-dimension);
}

.move-report-modal .list-button-card h2 {
  font-size: 1.25rem;
  font-weight: normal;
}

.move-report-modal-pagination-container {
  display: flex;
  justify-content: center;
}

.move-report-modal-search-bar {
  display: flex;
  justify-content: space-between;
}
