.card {
  @extend %block-element-margins;
  display: block;
  padding: var(--card-padding);
  background-color: var(--card-background-color);
}

.card:focus {
  background-color: var(--soft-blue-color);
  border-color: var(--highlight-color);
  outline: none;
}

a.card {
  text-decoration: none;
  color: inherit;
}

.card > *:last-child {
  margin-bottom: 0;
}

.card.indented-card {
  @extend %indented-card-border;
}
