.modal .modal-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--dimension-unit);
}

.modal .modal-toolbar button.button-link-appearance {
  font-weight: var(--regular-font-weight);
  color: var(--pure-white-color);
}

.modal .modal-toolbar button.button-link-appearance:not(:disabled):hover {
  color: var(--pure-white-color);
  opacity: 0.75;
}

.modal .modal-toolbar button.button-link-appearance .fa-icon {
  font-size: 1.25em;
}

.modal .modal-toolbar .button-link-appearance {
  color: var(--pure-white-color);
}

.modal .modal-content {
  padding: var(--wide-dimension);
  background-color: var(--secondary-background-color);
  border-radius: 3px;
}

.modal .modal-content .layout-container {
  width: inherit;
}

.modal .modal-content h2 {
  font-weight: var(--bold-font-weight);
}
