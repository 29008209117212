.union-list-page-heading {
  margin-top: 0;
  margin-bottom: var(--wide-dimension);
  display: flex;
}

.union-list-page-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.union-list-page-heading-left h1 {
  margin-bottom: 0;
}

.union-list-page-search-bar {
  @extend %block-element-margins;
  display: flex;
  justify-content: space-between;
}

.union-list-page-search-bar .general-search-input {
  width: 50%;
  height: 100%;
  margin-bottom: 0;
}

.union-list-page-search-bar .law-firm-sort-menu {
  margin-bottom: 0;
}

.union-list {
  @extend %block-element-margins;
}

.union-list-pagination-container {
  display: flex;
  justify-content: center;
}

@media print {
  .inset-col-2 {
    width: auto;
  }
  .inset-col-2 > .layout-column {
    margin: 0;
  }
  .list-card {
    break-inside: avoid;
    /* Remove padding to compensate for the bottom margin on the print properties: */
    padding-bottom: 0;
  }
}
