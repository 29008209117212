:root {
  --cities-list-card-content-color: var(--pure-white-color);
  --cities-list-card-control-height: 44px;
  --cities-list-card-city-code-width: 38px;
}

.cities-list-card {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
  background-color: var(--dark-blue-grey-color);
  color: var(--cities-list-card-content-color);
  border-radius: 5px;
}

.cities-list-card-content {
  display: flex;
  align-items: center;
}

.cities-list-card-form {
  display: flex;
  align-items: flex-start;
}

.cities-list-card-content-left,
.cities-list-card-form-left {
  display: flex;
  flex: 1;
}

.cities-list-card-content-right,
.cities-list-card-form-right {
  display: flex;
}

.cities-list-card-content-left h2 {
  display: flex;
  align-items: center;
  height: var(--cities-list-card-control-height);
  padding-top: var(--dimension-unit);
  padding-bottom: var(--dimension-unit);
  margin-bottom: 0;
  border-bottom: 1px solid transparent;
}

.cities-list-card-content-left h2 > span {
  display: inline-block;
}

.cities-list-card-content-left h2 > span:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.cities-list-card-content-left h2 > span:first-child {
  min-width: var(--cities-list-card-city-code-width);
}

.cities-list-card-form-left > * {
  margin-right: var(--medium-dimension);
}

.cities-list-card-form-left .input-help {
  height: 100%;
}

.cities-list-card-form-left .input-help:first-child {
  width: var(--cities-list-card-city-code-width);
}

.cities-list-card-form-left .input-help:nth-child(2) {
  flex: 1;
}

.cities-list-card-form-left input {
  width: 66%;
  height: var(--cities-list-card-control-height);
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--cities-list-card-content-color);
  @extend %heading-fonts;
  font-size: var(--h2-font-size);
  color: var(--cities-list-card-content-color);
}

.cities-list-card-form-left
  input[type='text']:focus:not(:read-only):not(:disabled) {
  color: var(--cities-list-card-content-color);
  background-color: transparent;
  border-color: var(--cities-list-card-content-color);
}

.cities-list-card-form-left input[type='text']:read-only {
  color: var(--cities-list-card-content-color);
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.cities-list-card-form-left input::placeholder {
  font-size: var(--h2-font-size);
  line-height: 1;
  height: fit-content;
}

.cities-list-card-form-left .input-help:first-child input {
  width: 100%;
}

.cities-list-card-form-left .active-city-radio-container {
  display: flex;
  align-items: center;
  height: var(--cities-list-card-control-height);
}

.cities-list-card-form-right {
  height: var(--cities-list-card-control-height);
  margin-left: var(--wide-dimension);
}

.cities-list-card button.button-link-appearance {
  color: var(--cities-list-card-content-color);
}

.cities-list-card button.button-link-appearance:hover,
.cities-list-card button.button-link-appearance:active {
  color: var(--cities-list-card-content-color);
  opacity: 0.7;
}

.cities-list-card button.button-link-appearance:not(:last-child) {
  margin-right: var(--medium-dimension);
}
