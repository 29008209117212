/* Please read the print-layouts-README file before editing the print layouts. */

:root {
  --print-csq-question-number-width: 24px;
}

.csq-print-gutter {
  height: 20px;
}

.csq-print-tickbox {
  width: 14px;
  height: 14px;
  border: 1px solid var(--light-grey-color);
  border-radius: 3px;
}

.csq-print-tickbox-group {
  display: flex;
  align-items: center;
  height: 100%;

  & > span {
    margin-left: var(--dimension-unit);
  }
}

/* Header */

.csq-print-header {
  text-align: center;
}

.csq-print-header > .print-line:first-child {
  height: 21px;
  font-size: 14px;
  color: var(--light-grey-color);
}

.csq-print-header > .print-line:last-child {
  height: 24px;
}

.csq-print-header > .print-line:last-child > h1 {
  font-size: 18px;
  font-weight: var(--bold-font-weight);
  text-transform: uppercase;
  margin: 0;
}

/* Information */

.csq-print-information {
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}
.csq-print-information > .csq-print-information-row {
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 72px;
}
.csq-print-information
  > .csq-print-information-row:first-of-type
  > .csq-print-information-label {
  text-align: left;
  width: fit-content;
}
.csq-print-information > div:not(:first-of-type) {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.csq-print-information > div:nth-child(2) {
  float: left;
  margin-bottom: 16px;
}

.csq-print-information > div p {
  font-weight: var(--bold-font-weight);
  margin-bottom: var(--dimension-unit);
}
.csq-print-information div.lawyer-case-info {
  & .csq-print-information-field {
    width: 50%;
  }

  & .csq-print-information-label {
    width: 45%;
    float: left;
  }
}

.csq-print-information-gutter {
  height: 7px;
}

.csq-print-information-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 21px;
}

.csq-print-information-label {
  width: 22%;
  height: 100%;
  padding-right: var(--dimension-unit);
  border-bottom: 1px solid transparent;
  text-align: right;

  &:first-child {
    width: 16%;
  }
}

.csq-print-information-field {
  width: 31%;
  height: 100%;
  border-bottom: 1px solid var(--light-grey-color);
}

.csq-print-information .csq-print-tickbox-group {
  justify-content: flex-end;
  margin-top: 16px;
}

.csq-print-information-row:nth-child(5) {
  & .csq-print-information-field:nth-child(2) {
    width: 10%;
  }

  & .csq-print-information-label:nth-child(3) {
    width: 14%;
  }

  & .csq-print-information-field:nth-child(4) {
    width: 7%;
  }
}

/* Questions */

.csq-print-questions {
  display: flex;
  flex-direction: column;
  width: 84%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.csq-print-question {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.csq-print-question-text {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  font-weight: var(--bold-font-weight);

  & > *:first-child {
    width: var(--print-csq-question-number-width);
  }
}

.csq-print-question-answers {
  display: flex;
  margin-bottom: 5px;
}

.csq-print-question-answers .csq-print-tickbox-group {
  margin-left: var(--print-csq-question-number-width);
}

.csq-print-comments-line {
  height: 30px;
  margin-left: var(--print-csq-question-number-width);
  border-bottom: 1px solid var(--light-grey-color);
}

/* Footer */

.csq-print-footer {
  font-size: 12px;
  text-align: center;
}

/* Language-specific overrides: */

.csq-print-layout-fr .csq-print-information-label {
  width: 24%;

  &:first-child {
    width: 22%;
  }
}

.csq-print-layout-fr .csq-print-information-field {
  width: 27%;
}

.csq-print-layout-fr .csq-print-information-row:nth-child(5) {
  & .csq-print-information-field:nth-child(2) {
    width: 10%;
  }

  & .csq-print-information-label:nth-child(3) {
    width: 11%;
  }

  & .csq-print-information-field:nth-child(4) {
    width: 6%;
  }
}

.csq-print-layout-fr .csq-print-questions {
  width: 94%;
}
