.lawyer-print-header h1 {
  display: flex;
  align-items: center;
}

.lawyer-print-header h1 span {
  display: inline-block;
}

.lawyer-print-header h1 span:last-child {
  margin-left: var(--medium-dimension);
  font-size: var(--body-font-size);
  font-weight: var(--regular-font-weight);
  text-transform: uppercase;
  color: var(--medium-grey-color);
}
