table {
  @extend %block-element-margins;
  width: 100%;
  border-collapse: collapse;
  border-radius: var(--table-border-radius);
  overflow: hidden;
}

table thead {
  background-color: var(--table-header-background-color);
  color: var(--table-header-content-color);
}

table thead th {
  padding: var(--medium-dimension);
  font-weight: var(--bold-font-weight);
  line-height: 1.5rem;
  text-align: left;
}

table tbody {
  background-color: var(--table-body-background-color);
  color: var(--table-body-content-color);
}

table tbody tr:nth-child(2n + 2) {
  background-color: var(--table-body-alt-background-color);
}

table tbody td {
  padding-top: var(--narrow-dimension);
  padding-bottom: var(--narrow-dimension);
  padding-left: var(--medium-dimension);
  padding-right: var(--medium-dimension);
  line-height: 1.5rem;
  text-align: left;
}

table thead th:not:(:first-child),
table tbody td:not:(:first-child) {
  padding-left: var(--narrow-dimension);
}

table thead th:not:(:last-child),
table tbody td:not:(:last-child) {
  padding-right: var(--narrow-dimension);
}

@media print {
  table {
    font-size: var(--table-print-font-size);
  }

  table thead th,
  table tbody td {
    line-height: var(--table-print-line-height);
  }

  table thead {
    background-color: var(--light-grey-color);
    color: var(--primary-content-color);
  }

  table tbody {
    background-color: transparent;
    color: var(--primary-content-color);
  }

  table tbody tr:nth-child(2n + 2) {
    background-color: var(--x-light-grey-color);
  }
}
