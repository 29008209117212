%link-styles {
  font-family: var(--font-family);
  font-weight: var(--bold-font-weight);
  color: var(--link-color);
  text-decoration: none;

  &:hover,
  &:active {
    background-color: var(--link-hover-background-color);
    padding-left: var(--link-hover-offset);
    padding-right: var(--link-hover-offset);
    margin-left: calc(var(--link-hover-offset) * -1);
    margin-right: calc(var(--link-hover-offset) * -1);
  }
}
