@import './cities-list-card/styles';

:root {
  --province-detail-control-height: 60px;
}

.province-detail-heading {
  @extend %block-element-margins;
  display: flex;
}

.province-detail-heading-left,
.province-detail-heading-left form {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.province-detail-heading-left > *,
.province-detail-heading-left form > *:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.province-detail-heading-left form,
.province-detail-heading-left form .input-help {
  height: 100%;
}

.province-detail-heading-left form .input-help:first-child {
  width: 38px;
}

.province-detail-heading-left form .input-help:nth-child(2) {
  flex: 1;
}

.province-detail-heading-left input {
  width: 100%;
  height: var(--province-detail-control-height);
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--primary-content-color);
  @extend %heading-fonts;
  font-size: var(--h1-font-size);
  color: var(--primary-content-color);
}

.province-detail-heading-left
  input[type='text']:focus:not(:read-only):not(:disabled) {
  background-color: transparent;
}

.province-detail-heading-left form input[type='text']:read-only {
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.province-detail-heading-left input::placeholder {
  font-size: var(--h1-font-size);
  line-height: 1;
  height: fit-content;
}

.province-detail-heading-left .active-province-radio-container {
  display: flex;
  align-items: center;
  height: var(--province-detail-control-height);
}

.province-detail-heading-right {
  display: flex;
  align-items: flex-start;
}

.province-detail-heading-right .button.page-action-button {
  min-width: 150px;
  height: var(--province-detail-control-height);
}

.cities-list .cities-list-card {
  margin-bottom: var(--medium-dimension);
}
