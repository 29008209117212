:root {
  --csq-entry-question-number-width: 30px;
}

.csq-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.csq-summary .form-row:first-child {
  justify-content: space-between;
}
.csq-summary .form-column:first-child {
  width: 90%;
}

.csq-entry-question-group {
  margin-bottom: var(--medium-dimension);
}

.csq-entry-question-group h4 {
  display: flex;
  margin-bottom: var(--narrow-dimension);
}

.csq-entry-question-group h4 > *:first-child {
  width: var(--csq-entry-question-number-width);
}

.csq-entry-question-group:not(:last-child) .input-help {
  margin-left: var(--csq-entry-question-number-width);
}

.csq-entry-question-group .radio-group label {
  font-size: 0.875rem;
}

.csq-entry-question-group .radio-group label:not(:last-child) {
  margin-right: var(--medium-dimension);
}

.csq-entry-question-group textarea {
  width: 100%;
  height: 10rem;
  resize: none;
}

.csq-entry .button-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.csq-entry .button-bar button {
  min-width: 13rem;
  padding: var(--medium-dimension);
  margin-left: var(--narrow-dimension);
}
