.client-personal-information-form > .form-row:nth-child(2) {
  & > *:first-child {
    flex: 3;
  }

  & > *:nth-child(2) {
    flex: 6;
  }

  & > *:nth-child(3) {
    flex: 6;
  }

  & > *:nth-child(4) {
    flex: 3;
  }

  & > *:nth-child(5) {
    flex: 4;
  }

  & > *:last-child {
    flex: 2;
  }
}

.client-personal-information-form > .form-row:nth-child(3) {
  & > *:first-child {
    flex: 4;
  }

  & > *:nth-child(2) {
    flex: 7;
  }

  & > *:nth-child(3) {
    flex: 3;
  }

  & > *:nth-child(4) {
    flex: 4;
  }

  & > *:last-child {
    flex: 6;
  }
}

.client-address-form > .form-row {
  & > *:first-child {
    flex: 6;
  }

  & > *:nth-child(2) {
    flex: 3;
  }

  & > *:nth-child(3) {
    flex: 4;
  }

  & > *:nth-child(4) {
    flex: 3;
  }

  & > *:nth-child(5) {
    flex: 3;
  }

  & > *:last-child {
    flex: 5;
  }
}

.client-employment-form > .form-row {
  & > *:first-child {
    flex: 8;
  }

  & > *:nth-child(2) {
    flex: 6;
  }

  & > *:nth-child(3) {
    flex: 4;
  }

  & > *:nth-child(4) {
    flex: 4;
  }

  & > *:last-child {
    flex: 2;
  }
}

.client-account-form > .form-row {
  & > *:first-child {
    flex: 5;
  }

  & > *:nth-child(2) {
    flex: 5;
  }

  & > *:nth-child(3) {
    flex: 5;
  }

  & > *:last-child {
    flex: 9;
  }
}

.client-account-form .input-help-content a {
  font-weight: var(--bold-font-weight);
  color: var(--highlight-color);
}
