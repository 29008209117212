.legal-problem-codes-heading {
  margin-top: 0;
  margin-bottom: var(--wide-dimension);
  display: flex;
}

.legal-problem-codes-heading-left {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.legal-problem-codes-heading-left h1 {
  margin-bottom: 0;
}

.legal-problem-codes-search-bar {
  @extend %block-element-margins;
  display: flex;
  justify-content: space-between;
}

.legal-problem-codes-search-bar .general-search-input {
  width: 50%;
  height: 100%;
  margin-bottom: 0;
}

.legal-problem-codes-search-bar .legal-problem-codes-sort-menu {
  margin-bottom: 0;
}

.legal-problem-codes-list {
  @extend %block-element-margins;
}

.legal-problem-codes-list .list-card h2 span {
  display: inline-block;

  &:first-child {
    margin-right: var(--medium-dimension);
  }
}

.legal-problem-codes-pagination-container {
  display: flex;
  justify-content: center;
}
